import { DependencyList, EffectCallback, useEffect, useRef } from "react";
import { isEqual } from "lodash";

/**
 * Run a callback on mount only.
 * Deps are checked for changes and a warning is logged if they change.
 */
export function useOnMount(callback: EffectCallback, deps: DependencyList) {
  const depsRef = useRef(deps);
  if (!isEqual(depsRef.current, deps)) {
    console.warn("useOnMount does not support changing dependencies");
  }
  depsRef.current = deps;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);
}
