import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Dialog } from "@capacitor/dialog";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import { differenceInDays, differenceInMinutes, isSameDay } from "date-fns";
import { motion } from "framer-motion";
import { produce } from "immer";
import { ellipsisVertical } from "ionicons/icons";
import { VList, VListHandle } from "virtua";

import { css } from "../../styled-system/css";
import { Flex, styled } from "../../styled-system/jsx";
import emptyCamera from "../assets/empty-camera.png";
import bg1 from "../assets/snaps-bg1.png";
import { CardClose } from "../components/CardClose";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { FullScreenLoader } from "../components/FullScreenLoader";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { ReactionPopover } from "../components/ReactionPopover";
import { useReportModal } from "../components/useReportModal";
import { useTakeSnap } from "../components/useTakeSnap";
import { LAST_COMMUNITY_ONBOARDING_KEY } from "../lib/constants";
import {
  DeeplinkType,
  useDeeplinkAction,
} from "../lib/hooks/useDeeplinkAction";
import { useEmojiPicker } from "../lib/hooks/useEmojiPicker";
import { useLocalStorage } from "../lib/hooks/useLocalStorage";
import { useViewImage } from "../lib/hooks/useViewImage";
import { RouterOutput, trpc } from "../lib/trpc";
import { isWeb, showActionSheet } from "../lib/utils";

type Snap = RouterOutput["snap"]["getSnapFeedV2"]["snaps"][0];

interface ScrollToSnap {
  snapId: string;
  commentId?: string;
  setAt?: Date;
}

function SnapView({
  snap,
  scrollToSnap,
  onClearScrollToSnap,
  onViewImage,
}: {
  snap: Snap;
  scrollToSnap?: ScrollToSnap;
  onClearScrollToSnap: () => void;
  onViewImage: () => void;
}) {
  const utils = trpc.useUtils();
  const containerRef = useRef<HTMLIonContentElement | null>(null);
  const updateSnapOptimistically = useCallback(
    (apply: (draft: Snap) => void) =>
      utils.snap.getSnapFeedV2.setInfiniteData(
        {},
        produce((draft) => {
          draft?.pages.forEach((page) => {
            const draftSnap = page.snaps.find((s) => s.id === snap.id);
            if (!draftSnap) return;
            apply(draftSnap);
          });
        })
      ),
    [utils, snap.id]
  );
  const addCommentMutation = trpc.snap.addSnapComment.useMutation({
    onSuccess: (data) => {
      // optimistic update
      updateSnapOptimistically((draft) => {
        if (data.action === "add") {
          draft.snapComments.push(data.comment);
        } else if (data.action === "delete") {
          draft.snapComments = draft.snapComments.filter(
            (c) => c.id !== data.commentId
          );
        } else {
          data satisfies never;
        }
      });
    },
  });
  const emojiPicker = useEmojiPicker((emoji) => {
    addCommentMutation.mutate({
      snapId: snap.id,
      text: emoji,
      isReaction: true,
    });
  });
  const [newComment, setNewComment] = useState("");
  const sendTextComment = async () => {
    if (!newComment.trim()) return;
    await addCommentMutation.mutateAsync({
      snapId: snap.id,
      text: newComment,
      isReaction: false,
    });
    setNewComment("");
  };

  const reactions = snap.snapComments.filter((c) => c.isReaction);
  const textComments = snap.snapComments.filter((c) => !c.isReaction);

  const reportModal = useReportModal();

  useEffect(() => {
    if (
      scrollToSnap?.snapId !== snap.id ||
      differenceInMinutes(new Date(), scrollToSnap.setAt!) > 1
    )
      return;

    const scroll = () => {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    // todo scroll to specific comment?
    scroll();
    setTimeout(scroll, 500);
    onClearScrollToSnap();
  }, [onClearScrollToSnap, scrollToSnap, snap.id]);

  return (
    <IonCol
      key={snap.id}
      ref={containerRef}
      className="mask-me"
      size="12"
      sizeMd="6"
      sizeLg="4"
    >
      <IonCard>
        {snap.caption ? (
          <IonCardTitle
            className={css({ p: 2, textAlign: "center", textWrap: "balance" })}
          >
            {snap.caption}
          </IonCardTitle>
        ) : null}
        <styled.div
          css={{ position: "relative", minHeight: "40vh", bg: "gray.200" }}
        >
          <styled.img
            className="ph-no-capture"
            css={{
              width: "100%",
              height: "40vh",
              objectFit: "contain",
              bg: "gray.200",
            }}
            srcSet={snap.imageUrlSet
              .map((i) => `${i.url} ${i.width}w`)
              .join(", ")}
            sizes="(max-width: 320px) 320px, (max-width: 640px) 640px, 1280px"
            alt=""
            role="button"
            loading="lazy"
            onClick={onViewImage}
          />
          <IonButton
            className={css({ position: "absolute", top: 0, right: 0 })}
            fill="clear"
            size="small"
            onClick={() =>
              showActionSheet("Photo Actions", [
                {
                  title: "Report",
                  onClick: () =>
                    reportModal.open({
                      type: "snap",
                      id: snap.id,
                      authorName: snap.owner.name,
                      imageUrl: snap.imageUrlSet[0]?.url ?? "",
                      caption: snap.caption,
                    }),
                },
              ])
            }
          >
            <IonIcon icon={ellipsisVertical} />
          </IonButton>
        </styled.div>
        <IonCardHeader className={css({ flexDirection: "column" })}>
          <Flex
            css={{
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <IonCardSubtitle>
              {snap.communitySnaps.map((cs) => cs.community.name).join(", ")} -{" "}
              {new Date(snap.createdAt).toLocaleString(undefined, {
                hour: "numeric",
                minute: "2-digit",
              })}
            </IonCardSubtitle>
            <ReactionPopover reactions={reactions}>
              <Flex css={{ position: "relative", flexWrap: "wrap" }}>
                {reactions.map((reaction) => (
                  <styled.div key={reaction.id} css={{ fontSize: 24 }}>
                    {reaction.text}
                  </styled.div>
                ))}
              </Flex>
            </ReactionPopover>
          </Flex>
          <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
            <IonCardTitle>{snap.owner.name}</IonCardTitle>
            <Flex
              css={{
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                fill="clear"
                size="small"
                isLoading={
                  addCommentMutation.isPending &&
                  addCommentMutation.variables.isReaction
                }
                onClick={() => emojiPicker.setShowEmojiPicker(true)}
              >
                React
              </LoadingButton>
            </Flex>
          </Flex>
        </IonCardHeader>
        <IonCardContent>
          {textComments.map((comment, index) => (
            <styled.div
              key={comment.id}
              css={{
                position: "relative",
                display: "flex",
                padding: "10px",
                borderBottom:
                  index !== textComments.length - 1
                    ? "1px solid #ccc"
                    : undefined,
              }}
            >
              <styled.span css={{ fontWeight: "bold", mr: 2 }}>
                {comment.owner.name}:
              </styled.span>
              <styled.span>{comment.text}</styled.span>
              <styled.div css={{ flex: 1 }} />
              <IonButton
                className={css({ position: "absolute", top: "1px", right: 0 })}
                fill="clear"
                size="small"
                onClick={() =>
                  showActionSheet("Photo Comment Actions", [
                    {
                      title: "Report",
                      onClick: () =>
                        reportModal.open({
                          type: "snapComment",
                          id: comment.id,
                          authorName: comment.owner.name,
                          text: comment.text,
                        }),
                    },
                  ])
                }
              >
                <IonIcon icon={ellipsisVertical} />
              </IonButton>
            </styled.div>
          ))}
          {textComments.length === 0 && <p>No comments yet</p>}
          <Flex css={{ alignItems: "end", "& ion-button": { mb: 0 } }}>
            <IonTextarea
              className={css({ flex: 1, mr: 8 })}
              placeholder="Type a comment..."
              autoGrow
              rows={1}
              value={newComment}
              disabled={addCommentMutation.isPending}
              autocapitalize="sentences"
              spellcheck
              onIonInput={(e) => setNewComment(e.detail.value!)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  sendTextComment().catch(console.error);
                }
              }}
            />
            <LoadingButton
              isLoading={
                addCommentMutation.isPending &&
                !addCommentMutation.variables.isReaction
              }
              onClick={sendTextComment}
            >
              Send
            </LoadingButton>
          </Flex>
        </IonCardContent>
      </IonCard>
      {emojiPicker.renderModal()}
      {reportModal.render()}
    </IonCol>
  );
}

function SnapReminder({ user }: { user: { id: string; name: string } }) {
  const [reminderSentAt, setReminderSentAt] = useLocalStorage(
    `snap-reminder-${user.id}`,
    null as string | null
  );
  const remindDailySnapMutation = trpc.snap.remindDailySnap.useMutation({
    onSuccess: () => setReminderSentAt(new Date().toISOString()),
  });
  const reminderSentToday = useMemo(() => {
    if (!reminderSentAt) return false;
    const sentAt = new Date(reminderSentAt);
    const now = new Date();
    return isSameDay(sentAt, now);
  }, [reminderSentAt]);

  return (
    <IonItem key={user.id}>
      <IonLabel>{user.name}</IonLabel>
      <LoadingButton
        fill="clear"
        disabled={reminderSentToday}
        isLoading={remindDailySnapMutation.isPending}
        onClick={() => remindDailySnapMutation.mutate({ userId: user.id })}
      >
        {reminderSentToday ? "Reminder Sent" : "Remind"}
      </LoadingButton>
    </IonItem>
  );
}

export function SnapsPage() {
  const utils = trpc.useUtils();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [hideWelcome, setHideWelcome] = useLocalStorage(
    "hide-snaps-welcome",
    false
  );
  const meQuery = trpc.me.getMe.useQuery();
  const snapFeedQuery = trpc.snap.getSnapFeedV2.useInfiniteQuery(
    {},
    { getNextPageParam: (lastPage) => lastPage.nextCursor?.id }
  );
  const snapsByDay = useMemo(() => {
    const flatSnaps =
      snapFeedQuery.data?.pages.flatMap((page) => page.snaps) ?? [];
    return flatSnaps.reduce(
      (acc, snap) => {
        const date = new Date(snap.createdAt);
        const dateString = date.toLocaleDateString("default", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        if (!acc[dateString]) acc[dateString] = [];
        acc[dateString]!.push(snap);
        return acc;
      },
      {} as Record<string, Snap[]>
    );
  }, [snapFeedQuery.data]);
  const { ref: feedEndRef, inView } = useInView();
  const snapFeedQueryHasNextPage = snapFeedQuery.hasNextPage;
  const snapFeedQueryFetchNextPage = snapFeedQuery.fetchNextPage;
  useEffect(() => {
    if (inView && snapFeedQueryHasNextPage) {
      snapFeedQueryFetchNextPage().catch(console.error);
    }
  }, [inView, snapFeedQueryHasNextPage, snapFeedQueryFetchNextPage]);

  const [skipDailyGate, setSkipDailyGate] = useState(false);
  const [hasSubmittedDailySnap] =
    trpc.snap.hasSubmittedDailySnap.useSuspenseQuery({
      timezone,
    });
  const allUsersQuery = trpc.community.getAllUsers.useQuery();
  const pendingSnapUsers = useMemo(() => {
    // people who haven't submitted a snap yet today
    const latestDay = Object.values(snapsByDay)?.[0];
    const pendingUsers = allUsersQuery.data?.filter(
      (user) => !latestDay?.some((snap) => snap.ownerId === user.id)
    );
    return pendingUsers || [];
  }, [snapsByDay, allUsersQuery.data]);

  const takePicture = useTakeSnap({
    onSnapTaken: async () => {
      // optimistic update
      utils.snap.hasSubmittedDailySnap.setData({ timezone }, true);
      await snapFeedQuery.refetch();
    },
  });

  const [scrollToSnap, setScrollToSnap] = useState<ScrollToSnap>();
  const clearScrollToSnap = useCallback(() => setScrollToSnap(undefined), []);
  useDeeplinkAction(({ data }) => {
    if (
      data.type === DeeplinkType.OpenSnap ||
      data.type === DeeplinkType.OpenSnapComment
    ) {
      setScrollToSnap({
        snapId: data.snapId,
        commentId:
          data.type === DeeplinkType.OpenSnapComment
            ? data.commentId
            : undefined,
        setAt: new Date(),
      });
      void snapFeedQuery.refetch();
      return true;
    }
    return false;
  });

  const viewImage = useViewImage();
  const scrollRef = useRef<VListHandle>(null);
  const [isScrollAtTop, setIsScrollAtTop] = useState(true);

  // ionic tries to add padding when the keyboard opens, by default
  // with this page, this can cause jumping, so this disables it
  useEffect(() => {
    if (isWeb() || takePicture.shouldRender) return;
    const oldResizeModePromise = Keyboard.getResizeMode();
    void Keyboard.setResizeMode({ mode: KeyboardResize.None });
    return () =>
      void oldResizeModePromise.then((oldResizeMode) =>
        Keyboard.setResizeMode(oldResizeMode)
      );
  }, [takePicture.shouldRender]);

  const lastCommunityOnboarding = localStorage.getItem(
    LAST_COMMUNITY_ONBOARDING_KEY
  );
  if (takePicture.shouldRender) return takePicture.render();
  if (!hasSubmittedDailySnap && !skipDailyGate)
    return (
      <PageFrame immersive>
        <FancyImageScreen coverImage={bg1}>
          {lastCommunityOnboarding &&
          differenceInDays(new Date(), new Date(lastCommunityOnboarding)) <
            1 ? (
            <>
              <FancyImageScreen.Title>
                Welcome to your new community, post your first photo!
              </FancyImageScreen.Title>
              <FancyImageScreen.Subtitle css={{ mb: 4 }}>
                Bridge enables communities to stay closer together through daily
                photo sharing!
              </FancyImageScreen.Subtitle>
            </>
          ) : (
            <p>To access the photo feed, please upload a daily photo.</p>
          )}
          <IonButton onClick={() => takePicture.execute()}>
            Upload Photo
          </IonButton>
          {meQuery.data &&
            differenceInDays(new Date(), meQuery.data.createdAt) < 7 && (
              <IonButton
                fill="clear"
                onClick={async () => {
                  await Dialog.alert({
                    message:
                      "Bridge thrives when everyone shares a daily photo. Since you're new here, we're delighted to offer you an exclusive glimpse.",
                  });
                  setSkipDailyGate(true);
                }}
              >
                Skip for Now
              </IonButton>
            )}
        </FancyImageScreen>
      </PageFrame>
    );
  if (snapFeedQuery.isLoading) return <FullScreenLoader />;
  return (
    <PageFrame immersive scrollY={false}>
      {/* pull to refresh */}
      <IonRefresher
        className={css({ marginTop: "env(safe-area-inset-top)" })}
        slot="fixed"
        onIonRefresh={(e) =>
          snapFeedQuery.refetch().finally(() => e.detail.complete())
        }
      >
        <IonRefresherContent />
      </IonRefresher>
      {/* scroll to top button */}
      <motion.div
        className={css({
          position: "fixed",
          top: "env(safe-area-inset-top)",
          right: 0,
          left: 0,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
        initial={{ y: -50, opacity: 0 }}
        animate={{
          y: isScrollAtTop ? -50 : 5,
          opacity: isScrollAtTop ? 0 : 1,
        }}
        onClick={() => scrollRef.current?.scrollTo(0)}
      >
        <styled.button
          css={{
            bg: "gray.300",
            color: "black",
            rounded: "full",
            px: 4,
            py: 2,
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
          }}
        >
          Scroll to Top
        </styled.button>
      </motion.div>
      {viewImage.renderModal()}
      {/* main content */}
      <VList
        ref={scrollRef}
        className="ion-content-scroll-host"
        onScrollEnd={() => {
          setIsScrollAtTop((scrollRef.current?.scrollOffset || 0) < 50);
        }}
      >
        {Object.keys(snapsByDay).length === 0 ? (
          <IonCol size="12">
            <IonCard>
              <styled.img
                css={{ w: "100%", maxHeight: "40vh", objectFit: "cover" }}
                src={emptyCamera}
                alt=""
              />
              <IonCardHeader>
                <IonCardTitle>No Photos Yet</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <styled.p css={{ textAlign: "center" }}>
                  It looks like there are no photos to display. Start the fun by
                  uploading the first photo of the day!
                </styled.p>
                <IonButton expand="block" onClick={() => takePicture.execute()}>
                  Upload Photo
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        ) : (
          <IonRow className={css({ mt: "env(safe-area-inset-top)" })}>
            <IonButton
              className={css({ w: "100%", mx: 4 })}
              expand="full"
              onClick={() =>
                showActionSheet("Add More Content", [
                  {
                    title: "New Picture",
                    onClick: () =>
                      void takePicture.execute().catch(console.error),
                  },
                  // {
                  //   title: "New Voice Note",
                  //   onClick: () => {
                  //     console.log("Share");
                  //   },
                  // }
                ])
              }
            >
              Add More
            </IonButton>
          </IonRow>
        )}
        {Object.entries(snapsByDay).map(([date, snaps], index) => (
          <IonRow key={date}>
            <styled.div
              css={{
                marginTop: 4,
                marginBottom: 2,
                fontSize: "18px",
                fontWeight: "bold",
                color: "white",
                bg: "gray.800",
                padding: 2,
                textAlign: "center",
                w: "calc(100vw + 42px)",
              }}
            >
              {date}
            </styled.div>

            {index === 0 &&
              !hideWelcome &&
              snapFeedQuery.data?.pages.length === 1 &&
              snapFeedQuery.data.pages[0]!.snaps.length === 1 &&
              snapFeedQuery.data.pages[0]!.snaps[0]!.ownerId ===
                meQuery.data?.id && (
                <IonCard className={css({ position: "relative", w: "100%" })}>
                  <IonCardHeader>
                    <IonCardTitle>Welcome to the Photo Feed!</IonCardTitle>
                    <CardClose onClose={() => setHideWelcome(true)} />
                  </IonCardHeader>
                  <IonCardContent>
                    Congrats on submitting your first photo! 🎉
                    <br />
                    This feed will be updated daily with photos from your
                    community!
                  </IonCardContent>
                </IonCard>
              )}

            {snaps.map((snap) => (
              <SnapView
                key={snap.id}
                snap={snap}
                scrollToSnap={scrollToSnap}
                onClearScrollToSnap={clearScrollToSnap}
                onViewImage={() =>
                  viewImage.view({
                    url: snap.imageUrlSet[0]?.url ?? "",
                    filename: `snap-${snap.createdAt.toISOString().replace(/[.:]/g, "")}.jpeg`,
                  })
                }
              />
            ))}
            {/* after the first day, which should be the current day, show everyone who hasn't submitted a snap yet */}
            {index === 0 && (
              <IonCol size="12" sizeMd="6" sizeLg="4">
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      People who haven't submitted a photo yet
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {pendingSnapUsers.map((user) => (
                      <SnapReminder key={user.id} user={user} />
                    ))}
                    {pendingSnapUsers.length === 0 && (
                      <styled.p css={{ textAlign: "center" }}>
                        Everyone has submitted a photo today! 🎉
                      </styled.p>
                    )}
                  </IonCardContent>
                </IonCard>
              </IonCol>
            )}
          </IonRow>
        ))}
        <IonRow>
          {snapFeedQuery.isFetchingNextPage ? (
            <Flex css={{ justifyContent: "center", w: "100%" }}>
              <IonSpinner />
            </Flex>
          ) : (
            <div ref={feedEndRef} className={css({ h: 100 })} />
          )}
        </IonRow>
      </VList>
    </PageFrame>
  );
}
