import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { useInView } from "react-intersection-observer";
import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";
import { Clipboard } from "@capacitor/clipboard";
import { Dialog } from "@capacitor/dialog";
import {
  IonButton,
  IonIcon,
  IonSpinner,
  IonTextarea,
  useIonRouter,
} from "@ionic/react";
import { can } from "@repo/shared";
import {
  JSONValue,
  Media,
  Message,
  MessageUpdateReason,
  Paginator,
  Participant,
} from "@twilio/conversations";
import { isSameDay } from "date-fns";
import { motion } from "framer-motion";
import {
  addCircle,
  caretBack,
  caretForward,
  ellipsisVertical,
} from "ionicons/icons";
import { useSetAtom } from "jotai";
import { Observable } from "rxjs";
import { z } from "zod";

import { css } from "../../styled-system/css";
import { Flex, Stack, styled } from "../../styled-system/jsx";
import { MutedDesc } from "../components/base";
import { FullScreenLoader } from "../components/FullScreenLoader";
import { LoadingButton } from "../components/LoadingButton";
import { useReportModal } from "../components/useReportModal";
import { useViewUsersModal } from "../components/useViewUsersModal";
import { useBackBtn } from "../lib/hooks/useBackBtn";
import { useEmojiPicker } from "../lib/hooks/useEmojiPicker";
import { useLocalStorage } from "../lib/hooks/useLocalStorage";
import { useModal } from "../lib/hooks/useModal";
import { useObservableCallback } from "../lib/hooks/useObservableCallback";
import { useSubject } from "../lib/hooks/useSubject";
import { useToast } from "../lib/hooks/useToast";
import { useTwilioConversationClient } from "../lib/hooks/useTwilioConversationClient";
import { useUpdatingRef } from "../lib/hooks/useUpdatingRef";
import { useViewImage } from "../lib/hooks/useViewImage";
import { openChannelIdAtom } from "../lib/state";
import { RouterOutput, trpc } from "../lib/trpc";
import {
  ChannelConnectionState,
  generateBase64ImageUrl,
  showActionSheet,
} from "../lib/utils";

const MessageAttributes = z.object({
  reactions: z.record(z.record(z.boolean())).optional(), // emoji -> user id -> reacted
});

declare module "@twilio/conversations" {
  interface Message {
    persisted?: undefined;
    isBlocked?: boolean;
  }
}

interface PersistedMessage {
  sid: string;
  index: number;
  author: string | null;
  body: string | null;
  attributes: JSONValue;
  updateAttributes?: undefined; // todo buffer
  attachedMedia?: undefined;
  dateCreated: string | null;
  isBlocked?: boolean;

  // derived
  persisted?: {
    isMe: boolean;
    authorName: string | null;
    hasMedia?: boolean;
  };
}

function usePersistentMessages(channelId: string) {
  const meQuery = trpc.me.getMe.useQuery();
  const meRef = useUpdatingRef(meQuery.data);
  const channelQuery = trpc.channel.getChannel.useQuery({
    channelId,
  });
  const channelRef = useUpdatingRef(channelQuery.data);

  const [messages, setMessages] = useState<Message[] | null>(null);
  const [persistedMessages, setPersistedMessages] = useLocalStorage<
    PersistedMessage[]
  >(`channel-${channelId}-messages`, []);

  const setMessagesProxy = useCallback(
    (messages: Message[] | ((prev: Message[]) => Message[])) => {
      setMessages((oldMessages) => {
        let newMessages;
        if (typeof messages === "function") {
          newMessages = messages(oldMessages || []);
        } else {
          newMessages = messages;
        }
        setPersistedMessages(
          newMessages.slice(-20).map(
            (m): PersistedMessage => ({
              sid: m.sid,
              index: m.index,
              author: m.author,
              body: m.body,
              attributes: m.attributes,
              dateCreated: m.dateCreated?.toISOString() || null,
              isBlocked: m.isBlocked,

              persisted: {
                isMe: m.author === meRef.current?.id,
                authorName:
                  channelRef.current?.channelParticipants.find(
                    (p) => p.userId === m.author
                  )?.user.name || null,
                hasMedia: (m.attachedMedia?.length || 0) > 0,
              },
            })
          )
        );
        return newMessages;
      });
    },
    [channelRef, meRef, setPersistedMessages]
  );

  return [messages ?? persistedMessages, setMessagesProxy] as const;
}

function useChannel(channelId: string, channelSid: string | undefined) {
  const { convoClient, connectionState } = useTwilioConversationClient();
  const blockedUsersQuery = trpc.me.getMyBlockedUsers.useQuery();

  const activeConversation = useAsync(
    async () =>
      (channelSid && convoClient?.getConversationBySid(channelSid)) ||
      undefined,
    [convoClient, channelSid]
  );

  const [messages, setMessages] = usePersistentMessages(channelId);
  function sendMessage(newMessage: string) {
    activeConversation.result?.sendMessage(newMessage).catch(console.error);
  }
  function sendMedia(formData: FormData) {
    activeConversation.result
      ?.prepareMessage()
      .addMedia(formData)
      .buildAndSend()
      .catch(console.error);
  }

  const messagesQuery = useAsync(
    async (convo: typeof activeConversation.result) => {
      if (!convo) return;
      return convo.getMessages();
    },
    [activeConversation.result]
  );
  useEffect(() => {
    if (messagesQuery.result) setMessages(messagesQuery.result.items);
  }, [messagesQuery.result, setMessages]);

  const messagesPrevPageQuery = useAsyncCallback(
    async (paginator: Paginator<Message>) => {
      if (!paginator) return;
      const prevPage = await paginator.prevPage();
      setMessages((prevMessages) => [...prevMessages, ...prevPage.items]);
      return prevPage;
    }
  );
  const messagesPrevPageQueryRef = useUpdatingRef(messagesPrevPageQuery);

  const [typingIndicators, setTypingIndicators] = useState<
    Record<string, boolean>
  >({});
  // setup conversation event listeners
  useEffect(() => {
    const convo = activeConversation.result;
    if (!convo) return;

    const handleMessageAdded = (message: Message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    };
    const handleMessageUpdated = ({
      message,
    }: {
      message: Message;
      updateReasons: MessageUpdateReason[];
    }) => {
      setMessages((prevMessages) =>
        prevMessages.map((m) => (m.sid === message.sid ? message : m))
      );
    };
    const handleTypingStarted = (participant: Participant) => {
      setTypingIndicators((prevIndicators) => ({
        ...prevIndicators,
        [participant.identity || ""]: true,
      }));
    };
    const handleTypingEnded = (participant: Participant) => {
      setTypingIndicators((prevIndicators) => ({
        ...prevIndicators,
        [participant.identity || ""]: false,
      }));
    };

    convo.on("messageAdded", handleMessageAdded);
    convo.on("messageUpdated", handleMessageUpdated);
    convo.on("typingStarted", handleTypingStarted);
    convo.on("typingEnded", handleTypingEnded);

    return () => {
      convo.removeListener("messageAdded", handleMessageAdded);
      convo.removeListener("messageUpdated", handleMessageUpdated);
      convo.removeListener("typingStarted", handleTypingStarted);
      convo.removeListener("typingEnded", handleTypingEnded);
    };
  }, [activeConversation.result, setMessages]);

  const { ref: feedEndRef, inView } = useInView();
  const paginator = messagesPrevPageQuery.result || messagesQuery.result;
  useEffect(() => {
    if (
      inView &&
      paginator?.hasPrevPage &&
      !messagesPrevPageQueryRef.current.loading
    ) {
      messagesPrevPageQueryRef.current.execute(paginator).catch(console.error);
    }
  }, [inView, paginator, messagesPrevPageQueryRef]);

  const processedMessages = useMemo(
    () =>
      messages
        .sort((a, b) => a.index - b.index)
        .map((m) =>
          !blockedUsersQuery.data?.some((u) => u.blockedId === m.author)
            ? m
            : ({
                ...m,
                body: "",
                attachedMedia: [],
                isBlocked: true,
              } as unknown as Message)
        ),
    [blockedUsersQuery.data, messages]
  );

  // mark all messages as read
  const lastMessageId = processedMessages[processedMessages.length - 1]?.sid;
  useEffect(() => {
    if (lastMessageId)
      activeConversation.result?.setAllMessagesRead().catch(console.error);
  }, [activeConversation.result, lastMessageId]);

  return {
    messages: processedMessages,
    renderMessagesEnd: () =>
      messagesQuery.loading ||
      messagesPrevPageQuery.loading ||
      (messages.length === 0 &&
        connectionState.status === ChannelConnectionState.CONNECTING) ? (
        <Flex css={{ justifyContent: "center", w: "100%" }}>
          <IonSpinner />
        </Flex>
      ) : (
        <div ref={feedEndRef} className={css({ h: 100 })} />
      ),
    sendMessage,
    sendMedia,
    connectionState,
    typingIndicators,
    conversation: activeConversation.result,
  };
}

function useInviteModal(
  channel: RouterOutput["channel"]["getChannel"] | undefined
) {
  const toast = useToast();
  const modal = useModal();
  const addChannelParticipantMutation =
    trpc.channel.addChannelParticipant.useMutation({
      onSuccess: () => {
        toast("Member added to channel", { color: "success" });
        modal.close();
      },
    });

  const communityQuery = trpc.community.getCommunity.useQuery(
    { communityId: channel?.communityId! },
    { enabled: !!channel?.communityId }
  );
  const invitableUsers = useMemo(() => {
    if (!communityQuery.data) return [];
    return communityQuery.data.communityUsers.filter(
      (user) =>
        !channel?.channelParticipants.some(
          (participant) => participant.userId === user.userId
        )
    );
  }, [communityQuery.data, channel?.channelParticipants]);

  return {
    open: modal.open,
    render: () =>
      modal.render(
        { title: `Add to ${channel?.name}` },
        <>
          {invitableUsers.map((user) => (
            <Flex
              key={user.userId}
              css={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <span>
                {user.user.name
                  ? `${user.user.name} (${user.user.phone})`
                  : user.user.phone}
              </span>
              <LoadingButton
                fill="clear"
                isLoading={
                  addChannelParticipantMutation.isPending &&
                  addChannelParticipantMutation.variables.userId === user.userId
                }
                disabled={addChannelParticipantMutation.isPending}
                onClick={() =>
                  addChannelParticipantMutation.mutate({
                    channelId: channel?.id!,
                    userId: user.userId,
                  })
                }
              >
                Add
              </LoadingButton>
            </Flex>
          ))}
          {invitableUsers.length === 0 && (
            <Flex css={{ justifyContent: "center", alignItems: "center" }}>
              <span>No invitable users</span>
            </Flex>
          )}
          {channel?.autoAdd && (
            <MutedDesc css={{ marginTop: 10 }}>
              This channel is auto-joined by all community members.
            </MutedDesc>
          )}
          <MutedDesc css={{ marginTop: 10 }}>
            If you're not seeing someone, make sure they're a member of the
            community.
          </MutedDesc>
        </>
      ),
  };
}

function MediaViewSpinner() {
  return <IonSpinner className={css({ m: 4 })} />;
}

function MediaView({ media }: { media: Media }) {
  const mediaUrl = useAsync(
    async (media: Media) => media.getContentTemporaryUrl(),
    [media]
  );

  if (mediaUrl.loading) return <MediaViewSpinner />;
  return <img className="ph-no-capture" alt="Media" src={mediaUrl.result!} />;
}

function ChannelPage({
  channelId,
  modalActionObservable,
}: {
  channelId: string;
  modalActionObservable: Observable<true>;
}) {
  const toast = useToast();
  const router = useIonRouter();
  const { data: me } = trpc.me.getMe.useQuery();
  const channelQuery = trpc.channel.getChannel.useQuery({
    channelId,
  });
  const communityQuery = trpc.community.getCommunity.useQuery(
    { communityId: channelQuery.data?.communityId! },
    { enabled: !!channelQuery.data?.communityId }
  );

  const {
    messages,
    renderMessagesEnd,
    sendMessage: sendMessageImpl,
    sendMedia: sendMediaImpl,
    connectionState,
    typingIndicators,
    conversation,
  } = useChannel(channelId, channelQuery.data?.externalId);
  const sendPushNotificationMutation =
    trpc.channel.sendPushNotification.useMutation();
  const [newMessage, setNewMessage] = useState("");
  const sendMessage = () => {
    if (newMessage.trim()) {
      sendMessageImpl(newMessage);
      sendPushNotificationMutation.mutate({
        channelId,
        message: newMessage,
      });
      setNewMessage("");
    }
  };
  const sendMedia = (formData: FormData) => {
    sendMediaImpl(formData);
    sendPushNotificationMutation.mutate({
      channelId,
      message: "Uploaded an image",
    });
  };
  const [selectedMessageSid, setSelectedMessageSid] = useState<string | null>(
    null
  );
  const emojiPicker = useEmojiPicker((emoji) => {
    const message = messages.find((m) => m.sid === selectedMessageSid);
    const meId = me?.id;
    if (!message || !meId) return;
    const attributes =
      MessageAttributes.safeParse(message.attributes).data || {};

    // toggle reaction
    const emojiReactions = attributes.reactions?.[emoji] || {};
    emojiReactions[meId] = !emojiReactions[meId];

    message
      .updateAttributes?.({
        ...attributes,
        reactions: {
          ...attributes.reactions,
          [emoji]: emojiReactions,
        },
      })
      .catch(console.error);
    setSelectedMessageSid(null);
  });

  const takePicture = useAsyncCallback(() =>
    Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      direction: CameraDirection.Front,
      resultType: CameraResultType.Base64,
    })
  );
  const viewImage = useViewImage();

  const inviteModal = useInviteModal(channelQuery.data);
  const viewUsersModal = useViewUsersModal();
  const leaveChannelMutation = trpc.channel.leaveChannel.useMutation({
    onSuccess: () => {
      toast("You have left the channel", { color: "success" });
      router.push("/home", "back");
      setTimeout(() => window.location.reload(), 1000); // todo don't do this bad hack
    },
  });

  const onBack = () => router.push("/home", "back");
  useBackBtn(onBack);

  useObservableCallback(modalActionObservable, () =>
    showActionSheet("Channel Actions", [
      {
        title: "View Participants",
        onClick: () =>
          viewUsersModal.open({
            title: `${channelQuery.data?.name} Participants`,
            users:
              channelQuery.data?.channelParticipants.map((p) => p.user) || [],
          }),
      },
      ...(channelQuery.data &&
      communityQuery.data &&
      can("inviteToChannel", {
        user: me,
        community: communityQuery.data,
        channel: channelQuery.data,
      })
        ? [{ title: "Add Participants", onClick: inviteModal.open }]
        : []),
      {
        title: "Leave Channel",
        isDestructive: true,
        onClick: async () => {
          const { value } = await Dialog.confirm({
            title: "Confirm",
            message: `Are you sure you'd like to leave the channel?`,
          });
          if (value) leaveChannelMutation.mutate({ channelId });
        },
      },
    ])
  );

  const reportModal = useReportModal();

  if (leaveChannelMutation.isPending) return <FullScreenLoader />;
  return (
    <Stack css={{ h: "100%", gap: 0 }}>
      <motion.div
        initial={{
          padding: "8px",
          marginBottom: "8px",
          textAlign: "center",
          transformOrigin: "top",
        }}
        animate={connectionState.status}
        variants={{
          [ChannelConnectionState.CONNECTING]: {
            backgroundColor: "#f0f0f0",
            color: "#000",
            scaleY: 1,
            display: "block",
          },
          [ChannelConnectionState.CONNECTED]: {
            backgroundColor: "#007aff",
            color: "#fff",
            scaleY: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
            transitionEnd: { display: "none" },
          },
          [ChannelConnectionState.DISCONNECTING]: {
            backgroundColor: "#f0f0f0",
            color: "#000",
            scaleY: 1,
            display: "block",
          },
          [ChannelConnectionState.DISCONNECTED]: {
            backgroundColor: "#f0f0f0",
            color: "#000",
            scaleY: 1,
            display: "block",
          },
          [ChannelConnectionState.ERROR]: {
            backgroundColor: "#f00",
            color: "#fff",
            scaleY: 1,
            display: "block",
          },
        }}
      >
        {connectionState.message}
      </motion.div>
      <styled.div css={{ flex: 1 }} />
      <Stack
        css={{
          overflowY: "auto",
          padding: "16px",
          flexDir: "column-reverse",
        }}
      >
        <Stack>
          {renderMessagesEnd()}
          {messages.map((message, index) => {
            const isMe = message.persisted?.isMe || message.author === me?.id;
            const author = channelQuery.data?.channelParticipants.find(
              (participant) => participant.userId === message.author
            );
            const authorName =
              message.persisted?.authorName ||
              author?.user.name ||
              "Unknown User";
            const attrs = MessageAttributes.safeParse(message.attributes).data;

            return (
              <styled.div
                key={message.sid}
                className="mask-me"
                css={{
                  alignSelf: isMe ? "flex-end" : "flex-start",
                }}
              >
                {isMe ||
                messages[index - 1]?.author === message.author ? null : (
                  <styled.div css={{ fontWeight: "bold" }}>
                    {message.isBlocked ? "User blocked" : authorName}
                  </styled.div>
                )}
                <Flex
                  css={{
                    justifyContent: isMe ? "flex-end" : "flex-start",
                  }}
                >
                  {selectedMessageSid === message.sid && (
                    <styled.div
                      css={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        bg: "gray.100",
                        order: isMe ? 0 : 1,
                        h: "min-content",
                        rounded: "8px",
                        mx: "8px",
                      }}
                    >
                      {!isMe && (
                        <IonIcon
                          icon={caretBack}
                          className={css({
                            position: "absolute",
                            top: 0,
                            left: "-18px",
                            p: "8px",
                            color: "gray.100",
                          })}
                        />
                      )}
                      {message.body ? (
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={() => {
                            Clipboard.write({ string: message.body! }).catch(
                              console.error
                            );
                            toast("Copied to clipboard", {
                              color: "success",
                            });
                            setSelectedMessageSid(null);
                          }}
                        >
                          Copy
                        </IonButton>
                      ) : null}
                      {message.attachedMedia?.length ? (
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={async () => {
                            const url =
                              await message.attachedMedia![0]!.getContentTemporaryUrl();
                            if (!url) return;
                            viewImage.view({
                              url,
                              filename: `message-${message.dateCreated?.toISOString().replace(/[:.]/g, "")}.jpeg`,
                            });
                          }}
                        >
                          View
                        </IonButton>
                      ) : null}
                      <IonButton
                        fill="clear"
                        size="small"
                        onClick={() => emojiPicker.setShowEmojiPicker(true)}
                      >
                        React
                      </IonButton>
                      {/* <IonButton fill="clear" size="small">
                          Reply
                        </IonButton> */}
                      <IonButton
                        fill="clear"
                        size="small"
                        onClick={() =>
                          showActionSheet("Message Actions", [
                            {
                              title: "Report",
                              onClick: () =>
                                reportModal.open({
                                  type: "channelMessage",
                                  sid: message.sid,
                                  channelId,
                                  authorName,
                                  text: message.body || "",
                                  imageSid:
                                    message.attachedMedia?.[0]?.contentType ===
                                    "image/jpeg"
                                      ? message.attachedMedia?.[0].sid
                                      : undefined,
                                }),
                            },
                          ])
                        }
                      >
                        More
                      </IonButton>
                      {isMe && (
                        <IonIcon
                          icon={caretForward}
                          className={css({
                            position: "absolute",
                            top: 0,
                            right: "-18px",
                            p: "8px",
                            color: "gray.100",
                          })}
                        />
                      )}
                    </styled.div>
                  )}
                  <styled.div
                    css={{
                      position: "relative",
                      maxWidth: "80vw",
                      mb: "8px",
                      rounded: "8px",
                      bg: isMe ? "#007aff" : "#f0f0f0",
                      color: isMe ? "#fff" : "#000",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    role="button"
                    onClick={() =>
                      setSelectedMessageSid((s) =>
                        s === message.sid ? null : message.sid
                      )
                    }
                  >
                    {message.body ? (
                      <styled.div css={{ p: "8px" }}>{message.body}</styled.div>
                    ) : null}
                    {message.isBlocked ? (
                      <styled.div css={{ p: "8px", fontStyle: "italic" }}>
                        Message from blocked user
                      </styled.div>
                    ) : null}
                    {message.attachedMedia?.map((media) => (
                      <MediaView key={media.contentType} media={media} />
                    )) || null}
                    {message.persisted?.hasMedia ? <MediaViewSpinner /> : null}
                    {attrs?.reactions ? (
                      <styled.div
                        css={{
                          borderTop: "1px solid",
                          borderTopColor: isMe ? "white/30" : "black/20",
                          display: "flex",
                          justifyContent: isMe ? "flex-end" : "flex-start",
                          mt: "8px",
                          p: "8px",
                        }}
                      >
                        {Object.entries(attrs.reactions)
                          .map(([e, u]) => ({
                            emoji: e,
                            reactions: Object.values(u).filter((r) => r),
                          }))
                          .filter(({ reactions }) => reactions.length > 0)
                          .map(({ emoji, reactions }) => (
                            <styled.div key={emoji} css={{ mr: "8px" }}>
                              {emoji}
                              {reactions.length > 1 && (
                                <styled.span
                                  css={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {reactions.length}
                                </styled.span>
                              )}
                            </styled.div>
                          ))}
                      </styled.div>
                    ) : null}
                  </styled.div>
                </Flex>
                {message.dateCreated && (
                  <styled.div
                    css={{
                      fontSize: "12px",
                      color: "#888",
                      textAlign: isMe ? "right" : "left",
                    }}
                  >
                    {new Date(message.dateCreated).toLocaleString(undefined, {
                      // don't show date if it's the same as the previous message or if it's today and it's the first message
                      ...(!isSameDay(
                        new Date(
                          messages[index - 1]?.dateCreated || new Date()
                        ),
                        new Date(message.dateCreated)
                      ) && {
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }),
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </styled.div>
                )}
              </styled.div>
            );
          })}
          <Stack css={{ h: 5 }}>
            {Object.entries(typingIndicators).map(([identity, isTyping]) => {
              const author = channelQuery.data?.channelParticipants.find(
                (participant) => participant.userId === identity
              );
              return (
                <styled.div key={identity}>
                  {isTyping
                    ? `${author?.user.name || "Unknown User"} is typing...`
                    : ""}
                </styled.div>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      <Stack css={{ p: "8px", borderTop: "1px solid #eee" }}>
        <Flex css={{ alignItems: "end", "& ion-button": { mb: 0 } }}>
          <IonButton
            fill="clear"
            onClick={async () => {
              const result = await takePicture.execute();
              if (!result.base64String) return;
              const imageBlob = await (
                await fetch(generateBase64ImageUrl(result.base64String)!)
              ).blob();

              const formData = new FormData();
              formData.append("file", imageBlob, "image.jpg");
              sendMedia(formData);
            }}
          >
            <IonIcon icon={addCircle} />
          </IonButton>
          <IonTextarea
            className={css({ flex: 1, mr: 8 })}
            placeholder="Type a message..."
            autoGrow
            rows={1}
            value={newMessage}
            autocapitalize="sentences"
            spellcheck
            onIonInput={(e) => setNewMessage(e.detail.value!)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              } else {
                conversation?.typing().catch(console.error);
              }
            }}
          />
          <IonButton onClick={sendMessage}>Send</IonButton>
        </Flex>
      </Stack>
      {emojiPicker.renderModal()}
      {viewImage.renderModal()}
      {inviteModal.render()}
      {viewUsersModal.render()}
      {reportModal.render()}
    </Stack>
  );
}

export function useChannelModal() {
  const modalActionSubject = useSubject();
  const modal = useModal<{
    channelId: string;
    channelName: string | undefined;
  }>();
  const channelQuery = trpc.channel.getChannel.useQuery(
    { channelId: modal.value?.channelId! },
    { enabled: !!modal.value?.channelId }
  );

  const setOpenChannelId = useSetAtom(openChannelIdAtom);
  useEffect(
    () => setOpenChannelId(modal.value?.channelId || null),
    [modal.value?.channelId, setOpenChannelId]
  );

  return {
    open: modal.open,
    render: () =>
      modal.render(
        {
          title: (value) =>
            channelQuery.data?.name || value.channelName || "Channel",
          action: {
            label: <IonIcon icon={ellipsisVertical} />,
            onClick: () => modalActionSubject.next(true),
          },
          fullScreen: true,
        },
        (value) => (
          <ChannelPage
            channelId={value.channelId}
            modalActionObservable={modalActionSubject}
          />
        )
      ),
  };
}
