import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { newId } from "@repo/shared";
import { differenceInMinutes } from "date-fns";

import { useUpdatingRef } from "./useUpdatingRef";

export enum DeeplinkType {
  OpenChannel = "openChannel",
  OpenSnap = "openSnap",
  OpenSnapComment = "openSnapComment",
}

export interface DeeplinkAction {
  data:
    | {
        type: DeeplinkType.OpenChannel;
        channelId: string;
      }
    | {
        type: DeeplinkType.OpenSnap;
        snapId: string;
      }
    | {
        type: DeeplinkType.OpenSnapComment;
        snapId: string;
        commentId: string;
      };
  id: string;
  createdAt: Date;
}

type DeeplinkContextType = {
  deeplinkActions: DeeplinkAction[];
  handledDeeplinkActions: MutableRefObject<string[]>;
  addDeeplinkAction: (actionData: DeeplinkAction["data"]) => void;
  removeDeeplinkAction: (actionId: string) => void;
};

const DeeplinkContext = createContext<DeeplinkContextType | undefined>(
  undefined
);

export function DeeplinkProvider({ children }: { children: React.ReactNode }) {
  const [deeplinkActions, setDeeplinkActions] = useState<DeeplinkAction[]>([]);
  const handledDeeplinkActions = useRef<string[]>([]);

  const addDeeplinkAction = useCallback(
    (actionData: Parameters<DeeplinkContextType["addDeeplinkAction"]>[0]) =>
      setDeeplinkActions((actions) => [
        ...actions,
        { data: actionData, id: newId.deeplinkAction(), createdAt: new Date() },
      ]),
    []
  );
  const removeDeeplinkAction = useCallback((actionId: string) => {
    setDeeplinkActions((actions) => actions.filter((a) => a.id !== actionId));
    handledDeeplinkActions.current.push(actionId);
  }, []);

  const value = useMemo(() => {
    return {
      deeplinkActions,
      handledDeeplinkActions,
      addDeeplinkAction,
      removeDeeplinkAction,
    };
  }, [
    deeplinkActions,
    handledDeeplinkActions,
    addDeeplinkAction,
    removeDeeplinkAction,
  ]);

  return (
    <DeeplinkContext.Provider value={value}>
      {children}
    </DeeplinkContext.Provider>
  );
}

export function useDeeplinkAction(
  handler: (action: DeeplinkAction) => boolean
) {
  const context = useContext(DeeplinkContext);
  if (!context) {
    throw new Error("useDeeplinkAction must be used within a DeeplinkProvider");
  }

  const handlerRef = useUpdatingRef(handler);
  const { deeplinkActions, handledDeeplinkActions, removeDeeplinkAction } =
    context;

  useEffect(() => {
    deeplinkActions.forEach((action) => {
      if (
        handledDeeplinkActions.current.includes(action.id) ||
        differenceInMinutes(new Date(), action.createdAt) > 1
      )
        return;

      if (handlerRef.current(action)) {
        removeDeeplinkAction(action.id);
      }
    });
  }, [
    deeplinkActions,
    handledDeeplinkActions,
    handlerRef,
    removeDeeplinkAction,
  ]);
}

export function useAddDeeplinkAction() {
  const context = useContext(DeeplinkContext);
  if (!context) {
    throw new Error(
      "useAddDeeplinkAction must be used within a DeeplinkProvider"
    );
  }

  return context.addDeeplinkAction;
}
