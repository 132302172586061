import { useCallback, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { cx } from "../../../styled-system/css";
import { useUpdatingRef } from "./useUpdatingRef";

export function useModal<T = boolean>({
  onClose,
}: { onClose?: () => void } = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null as T | null);
  const open = useCallback((...args: T extends boolean ? [] : [value: T]) => {
    setIsOpen(true);
    setValue(args.length === 0 ? (true as T) : args[0]);
  }, []);
  const onCloseRef = useUpdatingRef(onClose);
  const close = useCallback(() => {
    setIsOpen(false);
    onCloseRef.current?.();
  }, [onCloseRef]);

  return {
    open,
    close,
    value,
    render: (
      {
        title,
        action,
        hideBackButton,
        fullScreen,
        noPadding,
        onDidPresent,
      }: {
        title: string | ((value: T) => React.ReactNode);
        action?:
          | { label: React.ReactNode; onClick: () => void }
          | React.ReactNode;
        hideBackButton?: boolean;
        fullScreen?: boolean;
        noPadding?: boolean;
        onDidPresent?: () => void;
      },
      children: React.ReactNode | ((value: T) => React.ReactNode)
    ) => (
      <IonModal
        className={cx(fullScreen && "modal-fullscreen")}
        isOpen={isOpen}
        onDidPresent={onDidPresent}
        onDidDismiss={() => {
          close();
          setValue(null);
        }}
      >
        {value !== null ? (
          <>
            <IonHeader>
              <IonToolbar>
                {!hideBackButton && (
                  <IonButtons slot="start">
                    <IonButton onClick={close}>Back</IonButton>
                  </IonButtons>
                )}
                <IonTitle>
                  {typeof title === "function" ? title(value) : title}
                </IonTitle>
                {action ? (
                  <IonButtons slot="end">
                    {typeof action === "object" && "label" in action ? (
                      <IonButton onClick={action.onClick}>
                        {action.label}
                      </IonButton>
                    ) : (
                      action
                    )}
                  </IonButtons>
                ) : null}
              </IonToolbar>
            </IonHeader>
            <IonContent className={cx(!noPadding && "ion-padding")}>
              {typeof children === "function" ? children(value) : children}
            </IonContent>
          </>
        ) : null}
      </IonModal>
    ),
  };
}
