import { isMobile } from "react-device-detect";
import { IonButton } from "@ionic/react";

import { Stack } from "../../styled-system/jsx";
import appStoreDownload from "../assets/app-store-download.svg";
import bg1 from "../assets/onboarding-bg1.png";
import { MutedDesc } from "../components/base";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { PageFrame } from "../components/PageFrame";
import { useLocalStorage } from "../lib/hooks/useLocalStorage";
import { isWeb } from "../lib/utils";

const appStoreLink = "https://apps.apple.com/app/id6499193338";

export function MobileGate({ children }: { children: React.ReactNode }) {
  const [skipMobileGate, setSkipMobileGate] = useLocalStorage(
    "skipMobileGate",
    false
  );

  if (isWeb() && isMobile && !skipMobileGate) {
    return (
      <PageFrame immersive>
        <FancyImageScreen coverImage={bg1} showLogo>
          <FancyImageScreen.Title>Welcome to bridge</FancyImageScreen.Title>
          <MutedDesc>
            Bridge helps you stay connected, so you can focus on what matters
            most.
          </MutedDesc>
          <Stack css={{ justifyContent: "center", alignItems: "center" }}>
            <a href={appStoreLink} target="_blank">
              <img
                style={{ width: 200 }}
                src={appStoreDownload}
                alt="Download on the App Store"
              />
            </a>
            <IonButton fill="clear" onClick={() => setSkipMobileGate(true)}>
              Skip for now
            </IonButton>
          </Stack>
        </FancyImageScreen>
      </PageFrame>
    );
  }
  return <>{children}</>;
}
