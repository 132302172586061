import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { IonInput } from "@ionic/react";

import { css } from "../../styled-system/css";
import bg1 from "../assets/onboarding-bg1.png";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { trpc } from "../lib/trpc";
import { onEnter } from "../lib/utils";

enum Step {
  SetName,
}

function StepSetName({ onComplete }: { onComplete: () => void }) {
  const onCompleteAsync = useAsyncCallback(onComplete);
  const [profileName, setProfileName] = useState("");
  const setNameMutation = trpc.me.setName.useMutation({
    onSuccess: onCompleteAsync.execute,
  });
  const saveName = () => setNameMutation.mutate({ name: profileName });

  const [input, setInput] = useState<HTMLIonInputElement | null>(null);
  useEffect(() => {
    setTimeout(() => void input?.setFocus(), 100);
  }, [input]);

  return (
    <FancyImageScreen coverImage={bg1}>
      <FancyImageScreen.Title>What's your name?</FancyImageScreen.Title>
      <IonInput
        ref={setInput}
        className={css({ textAlign: "center" })}
        placeholder="Your Name"
        autofocus
        value={profileName}
        autocapitalize="words"
        spellcheck
        onIonInput={(e) => setProfileName(e.detail.value!)}
        onKeyDown={onEnter(saveName)}
      />
      <LoadingButton
        disabled={!profileName}
        isLoading={setNameMutation.isPending || onCompleteAsync.loading}
        onClick={saveName}
      >
        Continue
      </LoadingButton>
    </FancyImageScreen>
  );
}

export function Onboarding({
  onComplete,
}: {
  onComplete: () => Promise<unknown>;
}) {
  const [step] = useState(Step.SetName);

  function renderStep() {
    switch (step) {
      case Step.SetName:
        return <StepSetName onComplete={onComplete} />;
    }
  }

  return <PageFrame immersive>{renderStep()}</PageFrame>;
}
