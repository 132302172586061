import { IonContent, IonFooter, IonHeader, IonToolbar } from "@ionic/react";

import { Stack } from "../../styled-system/jsx";

export function PageFrame({
  contentRef,
  header,
  immersive,
  children,
  scrollY,
}: {
  contentRef?: React.Ref<HTMLIonContentElement>;
  header?: React.ReactNode;
  immersive?: boolean;
  children: React.ReactNode;
  scrollY?: boolean;
}) {
  return (
    <Stack css={{ gap: 0, height: "100%" }}>
      {header ? (
        <IonHeader className={immersive ? "transparent-header" : undefined}>
          <IonToolbar>{header}</IonToolbar>
        </IonHeader>
      ) : !immersive ? (
        <div style={{ height: "var(--ion-safe-area-top)" }} />
      ) : null}
      <IonContent
        ref={contentRef}
        fullscreen={immersive}
        scrollY={scrollY}
        className={immersive || scrollY === false ? undefined : "ion-padding"}
      >
        {children}
      </IonContent>
      <IonFooter></IonFooter>
    </Stack>
  );
}
