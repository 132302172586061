import parsePhoneNumberFromString from "libphonenumber-js";
import { z } from "zod";

export const zPhone = z.string().transform((arg, ctx) => {
  const phone = parsePhoneNumberFromString(arg, {
    defaultCountry: "US",
    extract: false,
  });

  if (phone?.isValid()) return phone.number;

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: "Invalid phone number",
  });
  return z.NEVER;
});

export function isTestPhone(phone: string) {
  // https://en.wikipedia.org/wiki/555_(telephone_number)
  return phone.match(/55501\d{2}/);
}
