import { CommunityFeatureConfig } from "./constants";

export enum Roles {
  Admin = "admin",
  Member = "member",
}

interface BaseInput {
  community: {
    id: string;
    ownerId: string;
    featureConfig: CommunityFeatureConfig;
  };
  user:
    | {
        id: string;
        communityUsers: { communityId: string; role: Roles }[];
      }
    | undefined; // undefined for loading users
}

interface ChannelInput extends BaseInput {
  channel: { id: string; channelParticipants: { userId: string }[] };
}

type CommunityPermissionsInput = {
  editCommunityFeatures: BaseInput;
  renameCommunity: BaseInput;
  inviteToCommunity: BaseInput;
  removeFromCommunity: BaseInput;
  changeUserRole: BaseInput;
  createChannel: BaseInput;
  editChannel: ChannelInput;
  deleteChannel: ChannelInput;
  inviteToChannel: ChannelInput;
  removeFromChannel: ChannelInput;
};
type CommunityPermissions = keyof CommunityPermissionsInput;

export function can<T extends CommunityPermissions>(
  permission: T,
  input: CommunityPermissionsInput[T]
) {
  if (!input.user) return false;

  const isOwner = input.community.ownerId === input.user.id;
  if (isOwner) return true;

  const communityUser = input.user.communityUsers.find(
    (user) => user.communityId === input.community.id
  );
  if (!communityUser) return false;

  const roleFeatures = input.community.featureConfig.roles;

  if (roleFeatures?.enabled) {
    const isAdmin = communityUser.role === Roles.Admin;

    switch (permission) {
      case "editCommunityFeatures":
        return false;
      case "renameCommunity":
        return isAdmin;
      case "inviteToCommunity":
      case "removeFromCommunity":
      case "createChannel":
        return (
          isAdmin ||
          !!roleFeatures.memberPermissions?.[
            permission as
              | "inviteToCommunity"
              | "removeFromCommunity"
              | "createChannel"
          ]
        );
      case "changeUserRole":
        return isAdmin;
      case "editChannel":
      case "deleteChannel":
      case "inviteToChannel":
      case "removeFromChannel":
        const isChannelMember = (
          input as CommunityPermissionsInput[
            | "editChannel"
            | "deleteChannel"
            | "inviteToChannel"
            | "removeFromChannel"]
        ).channel.channelParticipants.some(
          (participant) => participant.userId === input.user!.id
        );
        return (
          isAdmin ||
          (isChannelMember &&
            !!roleFeatures.memberPermissions?.[
              permission as
                | "editChannel"
                | "deleteChannel"
                | "inviteToChannel"
                | "removeFromChannel"
            ])
        );
      default:
        permission satisfies never;
        return false;
    }
  } else {
    switch (permission) {
      case "editCommunityFeatures":
      case "renameCommunity":
      case "removeFromCommunity":
      case "changeUserRole": // not really applicable if roles are not enabled
        return false;
      case "inviteToCommunity":
      case "createChannel":
        return true;
      case "editChannel":
      case "deleteChannel":
      case "inviteToChannel":
      case "removeFromChannel":
        return (
          input as CommunityPermissionsInput[
            | "editChannel"
            | "deleteChannel"
            | "inviteToChannel"
            | "removeFromChannel"]
        ).channel.channelParticipants.some(
          (participant) => participant.userId === input.user!.id
        );
      default:
        permission satisfies never;
        return false;
    }
  }
}

export function assertCan<T extends CommunityPermissions>(
  permission: T,
  input: CommunityPermissionsInput[T]
) {
  if (!can(permission, input)) {
    throw new Error("User is not allowed to perform this action");
  }
}
