import { ActionSheet, ActionSheetButtonStyle } from "@capacitor/action-sheet";
import { Capacitor } from "@capacitor/core";

export function onEnter(callback: () => void) {
  return (e: React.KeyboardEvent) => {
    if (e.key === "Enter") callback();
  };
}

export function isWeb() {
  return Capacitor.getPlatform() === "web";
}

export async function showActionSheet(
  title: string,
  actions: {
    title: string;
    isDestructive?: boolean;
    onClick: () => void;
  }[]
) {
  const result = await ActionSheet.showActions({
    title,
    options: [
      ...actions.map((a) => ({
        title: a.title,
        style: a.isDestructive ? ActionSheetButtonStyle.Destructive : undefined,
      })),
      { title: "Cancel", style: ActionSheetButtonStyle.Cancel },
    ],
  });
  actions[result?.index]?.onClick();
}

export enum ChannelConnectionState {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  DISCONNECTING = "disconnecting",
  DISCONNECTED = "disconnected",
  ERROR = "error",
}

export function generateBase64ImageUrl(base64String?: string) {
  if (!base64String) return undefined;
  return `data:image/jpeg;base64,${base64String}`;
}
