import { useRef, useState } from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonBadge,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { trash } from "ionicons/icons";

import { css } from "../../styled-system/css";
import { Flex, Stack, styled } from "../../styled-system/jsx";
import { useAuth } from "../components/ApiProvider";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { useModal } from "../lib/hooks/useModal";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";
import { showActionSheet } from "../lib/utils";
import { useInviteModal } from "./InvitePage";

function clearStorage() {
  localStorage.clear();
}

function useRequestAccountDeletionModal() {
  const requestAccountDeletionMutation =
    trpc.me.requestAccountDeletion.useMutation({
      onSuccess: () => {
        clearStorage();
      },
    });
  const modal = useModal();

  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const emailRef = useRef<HTMLIonInputElement>(null);

  return {
    open: modal.open,
    render: () =>
      modal.render(
        {
          title: "Request Account Deletion",
          hideBackButton:
            requestAccountDeletionMutation.isPending ||
            requestAccountDeletionMutation.isSuccess,
          onDidPresent: () => void emailRef.current?.setFocus(),
        },
        requestAccountDeletionMutation.isSuccess ? (
          <IonText>
            Account deletion requested. If you provided an email, we'll reach
            out when the account is deleted.
          </IonText>
        ) : (
          <>
            <styled.div css={{ textAlign: "center", width: "100%", my: 10 }}>
              Are you sure you want to request account deletion?
            </styled.div>
            <IonItem>
              <IonInput
                ref={emailRef}
                label="Email (optional)"
                value={email}
                onIonInput={(e) => setEmail(e.detail.value || "")}
              />
            </IonItem>
            <IonItem>
              <IonTextarea
                label="Reason (optional)"
                value={reason}
                onIonInput={(e) => setReason(e.detail.value || "")}
              />
            </IonItem>
            <LoadingButton
              expand="full"
              color="danger"
              isLoading={requestAccountDeletionMutation.isPending}
              onClick={() =>
                requestAccountDeletionMutation.mutate({ email, reason })
              }
            >
              Request Account Deletion
            </LoadingButton>
          </>
        )
      ),
  };
}

export function AccountPage({ fcmToken }: { fcmToken: string | undefined }) {
  const toast = useToast();
  const auth = useAuth();
  const utils = trpc.useUtils();

  const [newCommunityName, setNewCommunityName] = useState("");
  const createCommunityMutation = trpc.community.createCommunity.useMutation({
    onSuccess: () => {
      toast("Community created", { color: "success" });
      void utils.community.invalidate();
      setNewCommunityName("");
    },
  });

  const communityInvitesQuery = trpc.community.getCommunityInvites.useQuery();
  const inviteCount = communityInvitesQuery.data?.length || 0;
  const acceptInviteMutation = trpc.community.acceptCommunityInvite.useMutation(
    {
      onSuccess: () => {
        toast("Invite accepted", { color: "success" });
        void utils.community.invalidate();
        void utils.snap.invalidate();
      },
    }
  );
  const declineInviteMutation =
    trpc.community.declineCommunityInvite.useMutation({
      onSuccess: () => {
        toast("Invite declined", { color: "success" });
        void utils.community.getCommunityInvites.invalidate();
      },
    });

  const meQuery = trpc.me.getMe.useQuery();
  const [profileName, setProfileName] = useState(meQuery.data?.name || "");
  const setNameMutation = trpc.me.setName.useMutation({
    onSuccess: () => {
      toast("Profile updated", { color: "success" });
    },
  });

  const logoutMutation = trpc.auth.logout.useMutation({
    onSuccess: () => {
      auth.setToken(null);
      clearStorage();
      window.location.reload();
    },
  });
  const inviteModal = useInviteModal();

  const requestAccountDeletionModal = useRequestAccountDeletionModal();

  return (
    <PageFrame>
      <IonAccordionGroup>
        <IonAccordion value="new-community">
          <IonItem slot="header" color="light">
            <IonLabel>Create a new community</IonLabel>
          </IonItem>
          <Flex className="ion-padding" slot="content">
            <IonInput
              placeholder="Community name"
              autocapitalize="words"
              spellcheck
              value={newCommunityName}
              onIonInput={(e) => setNewCommunityName(e.detail.value || "")}
            />
            <LoadingButton
              isLoading={createCommunityMutation.isPending}
              onClick={() =>
                createCommunityMutation.mutate({ name: newCommunityName })
              }
            >
              Create
            </LoadingButton>
          </Flex>
        </IonAccordion>
        <IonAccordion value="community-invites">
          <IonItem slot="header" color="light">
            <IonLabel>Community Invites</IonLabel>
            {inviteCount > 0 && (
              <IonBadge color="danger">{inviteCount}</IonBadge>
            )}
          </IonItem>
          <Stack className="ion-padding" slot="content">
            {inviteCount > 0 && (
              <IonList>
                {communityInvitesQuery.data?.map((invite) => (
                  <IonItemSliding key={invite.id}>
                    <IonItem>
                      <IonLabel>{invite.community.name}</IonLabel>
                      <LoadingButton
                        size="default"
                        isLoading={
                          acceptInviteMutation.isPending &&
                          acceptInviteMutation.variables.inviteId === invite.id
                        }
                        disabled={
                          acceptInviteMutation.isPending ||
                          declineInviteMutation.isPending
                        }
                        onClick={() =>
                          acceptInviteMutation.mutate({ inviteId: invite.id })
                        }
                      >
                        Accept
                      </LoadingButton>
                    </IonItem>

                    <IonItemOptions side="end">
                      <IonItemOption
                        color="danger"
                        onClick={() =>
                          declineInviteMutation.mutate({ inviteId: invite.id })
                        }
                      >
                        <IonIcon slot="icon-only" icon={trash} />
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ))}
              </IonList>
            )}
            {inviteCount === 0 ? <IonText>No invites</IonText> : null}
            <IonButton expand="full" onClick={() => inviteModal.open()}>
              Scan Invite
            </IonButton>
            {inviteModal.render()}
          </Stack>
        </IonAccordion>
        <IonAccordion value="actions">
          <IonItem slot="header" color="light">
            <IonLabel>Profile</IonLabel>
          </IonItem>
          <Stack className="ion-padding" slot="content">
            <Flex>
              <IonInput
                label="Name"
                autocapitalize="words"
                spellcheck
                value={profileName}
                onIonInput={(e) => setProfileName(e.detail.value || "")}
              />
              <LoadingButton
                isLoading={setNameMutation.isPending}
                onClick={() => setNameMutation.mutate({ name: profileName })}
              >
                Update
              </LoadingButton>
            </Flex>
            <LoadingButton
              className={css({ mt: 10 })}
              expand="full"
              isLoading={logoutMutation.isPending}
              onClick={() => logoutMutation.mutate({ fcmToken })}
            >
              Logout
            </LoadingButton>
            <IonButton
              fill="outline"
              onClick={() =>
                showActionSheet("More Options", [
                  {
                    title: "Request Account Deletion",
                    isDestructive: true,
                    onClick: () => requestAccountDeletionModal.open(),
                  },
                ])
              }
            >
              More Options
            </IonButton>
            {requestAccountDeletionModal.render()}
          </Stack>
        </IonAccordion>
      </IonAccordionGroup>
    </PageFrame>
  );
}
