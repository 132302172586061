import { Suspense, useEffect, useMemo, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { camera, peopleCircle, personCircle } from "ionicons/icons";
import posthog from "posthog-js";

import { ApiProvider, useAuth } from "./components/ApiProvider";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ErrorView } from "./components/ErrorView";
import { FullScreenLoader } from "./components/FullScreenLoader";
import { PortalContainer } from "./components/Portal";
import { UpdateProvider } from "./components/UpdateProvider";
import { LAST_COMMUNITY_ONBOARDING_KEY } from "./lib/constants";
import { useAppUrlRegister } from "./lib/hooks/useAppUrlRegister";
import { DeeplinkProvider } from "./lib/hooks/useDeeplinkAction";
import { useRegisterNotifications } from "./lib/hooks/useRegisterNotifications";
import { ToastProvider } from "./lib/hooks/useToast";
import { trpc } from "./lib/trpc";
import { AccountPage } from "./pages/AccountPage";
import { AuthPage } from "./pages/AuthPage";
import { CommunityOnboarding } from "./pages/CommunityOnboarding";
import { HomePage } from "./pages/HomePage";
import { InvitePage } from "./pages/InvitePage";
import { MobileGate } from "./pages/MobileGate";
import { Onboarding } from "./pages/Onboarding";
import { SnapsPage } from "./pages/SnapsPage";

function AppContent() {
  const router = useIonRouter();
  const communityInvitesQuery = trpc.community.getCommunityInvites.useQuery();
  const inviteCount = communityInvitesQuery.data?.length || 0;
  const [[me, communities], [meQuery, communitiesQuery]] =
    trpc.useSuspenseQueries((t) => [
      t.me.getMe(),
      t.community.getCommunities(),
    ]);
  const [showOnboarding, setShowOnboarding] = useState(!me.name);

  // Show create community if there are no communities
  const [showCreateCommunityImpl, setShowCreateCommunityImpl] = useState(false);
  const showCreateCommunity = useMemo(
    () => !communities.length || showCreateCommunityImpl,
    [showCreateCommunityImpl, communities]
  );
  useEffect(() => {
    // keep showing the create community screen if a community is added while it's open
    if (showCreateCommunity && !showCreateCommunityImpl)
      setShowCreateCommunityImpl(true);
  }, [showCreateCommunity, showCreateCommunityImpl]);

  useAppUrlRegister();
  const { fcmToken } = useRegisterNotifications();

  if (communitiesQuery.error)
    return <ErrorView error={communitiesQuery.error} />;
  if (showOnboarding)
    return (
      <Onboarding
        onComplete={async () => {
          await meQuery.refetch();
          setShowOnboarding(false);
        }}
      />
    );
  if (showCreateCommunity)
    return (
      <CommunityOnboarding
        onComplete={async () => {
          await communitiesQuery.refetch();
          router.push("/snaps");
          setShowCreateCommunityImpl(false);
          localStorage.setItem(
            LAST_COMMUNITY_ONBOARDING_KEY,
            new Date().toISOString()
          );
        }}
      />
    );
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Suspense fallback={<FullScreenLoader />}>
            <HomePage />
          </Suspense>
        </Route>
        <Route exact path="/snaps">
          <Suspense fallback={<FullScreenLoader />}>
            <SnapsPage />
          </Suspense>
        </Route>
        <Route exact path="/account">
          <AccountPage fcmToken={fcmToken} />
        </Route>
        <Route
          exact
          path="/invite/:inviteCode"
          render={(props) => (
            <InvitePage
              {...props}
              onAccept={async () => {
                await communitiesQuery.refetch();
                router.push("/snaps");
              }}
            />
          )}
        />
        <Route exact path="/">
          <Redirect to="/snaps" />
        </Route>
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="snaps" href="/snaps">
          <IonIcon aria-hidden="true" icon={camera} />
          <IonLabel>Photos</IonLabel>
        </IonTabButton>
        <IonTabButton tab="home" href="/home">
          <IonIcon aria-hidden="true" icon={peopleCircle} />
          <IonLabel>Community</IonLabel>
        </IonTabButton>
        <IonTabButton tab="account" href="/account">
          <IonIcon aria-hidden="true" icon={personCircle} />
          <IonLabel>Account</IonLabel>
          {inviteCount > 0 && <IonBadge color="danger">{inviteCount}</IonBadge>}
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
}

function Auth() {
  const auth = useAuth();
  const meQuery = trpc.me.getMe.useQuery(undefined, {
    throwOnError: false,
    enabled: auth.hasToken,
    suspense: auth.hasToken,
  });

  useEffect(() => {
    if (!meQuery.data) return;
    posthog.identify(meQuery.data.id, {
      email: meQuery.data.email,
      name: meQuery.data.name,
    });
  }, [meQuery.data]);

  // todo show an error view if there's no network connection, but not if the login expired
  if (!meQuery.data)
    return (
      <AuthPage
        onLogin={async (token) => {
          auth.setToken(token);
          await meQuery.refetch();
        }}
      />
    );
  return (
    <IonReactRouter>
      <AppContent />
    </IonReactRouter>
  );
}

export function App() {
  return (
    <IonApp>
      <ToastProvider>
        <UpdateProvider>
          <ApiProvider>
            <DeeplinkProvider>
              <MobileGate>
                <ErrorBoundary>
                  <Suspense fallback={<FullScreenLoader />}>
                    <Auth />
                    <PortalContainer />
                  </Suspense>
                </ErrorBoundary>
              </MobileGate>
            </DeeplinkProvider>
          </ApiProvider>
        </UpdateProvider>
      </ToastProvider>
    </IonApp>
  );
}
