import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

// import { App } from "@capacitor/app";
// import * as LiveUpdates from "@capacitor/live-updates";
import { isWeb } from "../lib/utils";

const UpdateContext = createContext<
  { blockUpdate: () => void; unblockUpdate: () => void } | undefined
>(undefined);

export function UpdateProvider(props: { children: React.ReactNode }) {
  const blockUpdateRef = useRef(0);
  const blockUpdate = useCallback(() => {
    blockUpdateRef.current++;
  }, []);

  const unblockUpdate = useCallback(() => {
    blockUpdateRef.current--;
  }, []);

  // Perform updates on app resume
  useEffect(() => {
    if (isWeb()) return;

    async function initializeUpdates() {
      // let updateAvailable = false;
      // // Register event to fire each time user resumes the app
      // App.addListener("resume", async () => {
      //   if (updateAvailable && blockUpdateRef.current === 0) {
      //     await LiveUpdates.reload();
      //   } else {
      //     const result = await LiveUpdates.sync();
      //     updateAvailable = result.activeApplicationPathChanged;
      //   }
      // });
      // // First sync on app load
      // const result = await LiveUpdates.sync();
      // updateAvailable = result.activeApplicationPathChanged;
    }
    initializeUpdates().catch(console.error);
  }, []);

  const value = useMemo(
    () => ({ blockUpdate, unblockUpdate }),
    [blockUpdate, unblockUpdate]
  );

  return (
    <UpdateContext.Provider value={value}>
      {props.children}
    </UpdateContext.Provider>
  );
}

/**
 * Blocks updates while the component is mounted
 */
export function useBlockUpdate() {
  const context = useContext(UpdateContext);
  if (!context) {
    throw new Error("useBlockUpdate must be used within a UpdateProvider");
  }
  useEffect(() => {
    context.blockUpdate();
    return () => context.unblockUpdate();
  }, [context]);
}
