import React from "react";
import { IonButton, IonSpinner } from "@ionic/react";

export function LoadingButton({
  isLoading,
  children,
  ...props
}: {
  isLoading: boolean;
} & React.ComponentProps<typeof IonButton>) {
  return (
    <IonButton {...props} disabled={isLoading || props.disabled}>
      {isLoading ? <IonSpinner name="crescent" /> : children}
    </IonButton>
  );
}
