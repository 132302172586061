import React from "react";

import { ErrorView } from "./ErrorView";

// Error boundaries currently have to be a class component.
export class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
}> {
  public override state = { error: null };
  public static getDerivedStateFromError(error: unknown) {
    console.error("ErrorBoundary caught an error", error);
    return { error };
  }

  public override render() {
    if (this.state.error) return <ErrorView error={this.state.error} />;
    return this.props.children;
  }
}
