import { useEffect, useMemo, useState } from "react";
import { Client as ConversationsClient } from "@twilio/conversations";

import { trpc } from "../trpc";
import { ChannelConnectionState } from "../utils";

export function useTwilioConversationClient() {
  const createAuthTokenMutation = trpc.channel.createAuthToken.useMutation();
  const authToken = createAuthTokenMutation.data;
  const refreshToken = createAuthTokenMutation.mutate;
  useEffect(() => void refreshToken(), [refreshToken]);

  const [connectionState, setConnectionState] = useState({
    status: ChannelConnectionState.CONNECTING,
    message: "Connecting to Chat...",
  });

  const convoClient = useMemo(() => {
    if (!authToken) return null;
    const client = new ConversationsClient(authToken);
    client.on("tokenAboutToExpire", () => refreshToken());
    client.on("connectionStateChanged", (state) => {
      if (state === "connecting") {
        setConnectionState({
          status: ChannelConnectionState.CONNECTING,
          message: "Connecting to Chat...",
        });
      } else if (state === "connected") {
        setConnectionState({
          status: ChannelConnectionState.CONNECTED,
          message: "You are connected!",
        });
      } else if (state === "disconnecting") {
        setConnectionState({
          status: ChannelConnectionState.DISCONNECTING,
          message: "Disconnecting from Chat...",
        });
      } else {
        if (state === "disconnected") {
          setConnectionState({
            status: ChannelConnectionState.DISCONNECTED,
            message: "You have disconnected.",
          });
        } else {
          setConnectionState({
            status: ChannelConnectionState.ERROR,
            message: "Failed to connect.",
          });
        }
        setTimeout(() => refreshToken(), 5000); // reconnect
      }
    });
    return client;
  }, [authToken, refreshToken]);
  return { convoClient, connectionState };
}
