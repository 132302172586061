import { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonInput,
  IonSpinner,
} from "@ionic/react";
import { CommunityType } from "@repo/shared";

import { css } from "../../styled-system/css";
import { Stack, styled } from "../../styled-system/jsx";
import bg2 from "../assets/onboarding-bg2.png";
import { MutedDesc } from "../components/base";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { useCommunityInviteModal } from "../components/useCommunityInviteModal";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";
import { onEnter } from "../lib/utils";
import { useInviteModal } from "./InvitePage";

enum Step {
  Intro,
  ViewInvites,
  CreateCommunity_SetType,
  CreateCommunity_SetName,
  CreateCommunity_Invite,
}

type StepState =
  | { type: Step.Intro }
  | { type: Step.ViewInvites }
  | { type: Step.CreateCommunity_SetType }
  | { type: Step.CreateCommunity_SetName; communityType: CommunityType }
  | {
      type: Step.CreateCommunity_Invite;
      communityId: string;
      communityType: CommunityType;
    };

function StepIntro({ onComplete }: { onComplete: () => void }) {
  return (
    <FancyImageScreen coverImage={bg2} showLogo>
      <FancyImageScreen.Title>What is bridge?</FancyImageScreen.Title>
      <styled.p css={{ textAlign: "center", my: 0 }}>
        Bridge is a place for you to connect with your family and local
        community.
        <br />
        <br />
        You can share photos, videos, and stories with your loved ones.
        <br />
        <br />
        Let's get started by setting you up with your first community!
      </styled.p>
      <IonButton onClick={onComplete}>Get Started</IonButton>
    </FancyImageScreen>
  );
}

function StepViewInvites({
  onScanInvite,
  onSkip,
  onComplete,
}: {
  onScanInvite: () => void;
  onSkip: () => void;
  onComplete: (communityId: string) => void;
}) {
  const toast = useToast();
  const utils = trpc.useUtils();
  const communityInvitesQuery = trpc.community.getCommunityInvites.useQuery(
    undefined,
    { refetchInterval: 3000 }
  );
  const inviteCount = communityInvitesQuery.data?.length || 0;
  const acceptInviteMutation = trpc.community.acceptCommunityInvite.useMutation(
    {
      onSuccess: (data) => {
        toast("Invite accepted", { color: "success" });
        void utils.community.invalidate();
        onComplete(data.communityId);
      },
    }
  );
  const communityName = communityInvitesQuery.data?.[0]?.community.name;
  const parsedFirstCommunityName = communityName
    ? communityName.replace(/^the /i, "").replace(/ community$/i, "")
    : "";

  const [showJoinInstructions, setShowJoinInstructions] = useState(false);

  function renderStep() {
    if (inviteCount > 0) {
      return (
        <>
          <FancyImageScreen.Title>
            {inviteCount === 1
              ? `You've been invited to join the ${parsedFirstCommunityName} community!`
              : `You've been invited to join ${inviteCount} communities!`}
          </FancyImageScreen.Title>
          <Stack>
            {communityInvitesQuery.data?.map((invite) => (
              <IonCard key={invite.id}>
                <IonCardHeader>
                  <IonCardTitle>{invite.community.name}</IonCardTitle>
                  <IonCardSubtitle>From {invite.inviter.name}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <LoadingButton
                    expand="full"
                    className={css({ mt: 4 })}
                    isLoading={
                      acceptInviteMutation.isPending &&
                      acceptInviteMutation.variables?.inviteId === invite.id
                    }
                    disabled={acceptInviteMutation.isPending}
                    onClick={() =>
                      acceptInviteMutation.mutate({ inviteId: invite.id })
                    }
                  >
                    Accept
                  </LoadingButton>
                </IonCardContent>
              </IonCard>
            ))}
          </Stack>
          <IonButton fill="clear" onClick={onSkip}>
            Create a community instead
          </IonButton>
        </>
      );
    } else if (showJoinInstructions) {
      return (
        <>
          <FancyImageScreen.Title>
            We couldn't find any pending invites
          </FancyImageScreen.Title>
          <FancyImageScreen.Subtitle>
            Ask an existing Bridge user for an invite.
          </FancyImageScreen.Subtitle>
          <IonButton expand="block" fill="solid" onClick={() => onScanInvite()}>
            Scan an Invite
          </IonButton>
          <IonButton
            expand="block"
            fill="clear"
            onClick={() => setShowJoinInstructions(false)}
          >
            Go back
          </IonButton>
        </>
      );
    } else if (communityInvitesQuery.isLoading) {
      return <IonSpinner name="crescent" />;
    }

    return (
      <>
        <FancyImageScreen.Title>
          Join or create a community!
        </FancyImageScreen.Title>
        <Stack css={{ alignItems: "center", textAlign: "center" }}>
          Do you have any friends or family already using Bridge?
          <div>
            <IonButton onClick={() => setShowJoinInstructions(true)}>
              Join a Community
            </IonButton>
          </div>
        </Stack>
        <styled.div css={{ bg: "blue.300", h: "1px", w: "100%", my: 2 }} />
        <Stack css={{ alignItems: "center", textAlign: "center" }}>
          <IonButton onClick={() => onSkip()}>Create a Community</IonButton>
        </Stack>
      </>
    );
  }

  return <FancyImageScreen coverImage={bg2}>{renderStep()}</FancyImageScreen>;
}

function StepCreateCommunitySetType({
  onComplete,
}: {
  onComplete: (type: CommunityType) => void;
}) {
  return (
    <FancyImageScreen coverImage={bg2}>
      <FancyImageScreen.Title>
        What type of community do you want to create and cultivate?
      </FancyImageScreen.Title>
      <Stack>
        {[
          CommunityType.Family,
          CommunityType.CommunityCenter,
          CommunityType.Other,
        ].map((type) => (
          <IonButton key={type} size="large" onClick={() => onComplete(type)}>
            {
              {
                [CommunityType.Family]: "Family",
                [CommunityType.CommunityCenter]: "Community Center",
                [CommunityType.Other]: "Other/Not Sure",
              }[type]
            }
          </IonButton>
        ))}
      </Stack>
      <MutedDesc>
        If you'd like to join an existing community, please ask for an invite
        from a community member.
      </MutedDesc>
    </FancyImageScreen>
  );
}

function StepCreateCommunitySetName({
  communityType,
  onComplete,
}: {
  communityType: CommunityType;
  onComplete: (communityId: string) => void;
}) {
  const utils = trpc.useUtils();
  const [name, setName] = useState("");
  const createCommunityMutation = trpc.community.createCommunity.useMutation({
    onSuccess: (data) => {
      onComplete(data.id);
      void utils.community.invalidate();
    },
  });
  const createCommunity = () =>
    createCommunityMutation.mutate({
      name,
      type: communityType,
    });

  return (
    <FancyImageScreen coverImage={bg2}>
      <FancyImageScreen.Header>
        <FancyImageScreen.Title>
          Let's start with your{" "}
          {
            {
              [CommunityType.Family]: "family",
              [CommunityType.CommunityCenter]: "community center",
              [CommunityType.Other]: "community",
            }[communityType]
          }
          's name!
        </FancyImageScreen.Title>
        <FancyImageScreen.Subtitle>
          For example, "
          {
            {
              [CommunityType.Family]: "The Smith Family",
              [CommunityType.CommunityCenter]: "The Smith Community Center",
              [CommunityType.Other]: "The Smith Community",
            }[communityType]
          }
          ".
        </FancyImageScreen.Subtitle>
      </FancyImageScreen.Header>
      <IonInput
        className={css({ textAlign: "center" })}
        placeholder="Community Name"
        value={name}
        autofocus
        autocapitalize="words"
        spellcheck
        onIonInput={(e) => setName(e.detail.value!)}
        onKeyDown={onEnter(createCommunity)}
      />
      <LoadingButton
        isLoading={createCommunityMutation.isPending}
        disabled={!name}
        onClick={createCommunity}
      >
        Continue
      </LoadingButton>
    </FancyImageScreen>
  );
}

function StepCreateCommunityInvite({
  communityId,
  communityType,
  onComplete,
}: {
  communityId: string;
  communityType: CommunityType;
  onComplete: () => void;
}) {
  const communityInviteModal = useCommunityInviteModal({
    onClose: onComplete,
  });

  return (
    <FancyImageScreen coverImage={bg2}>
      <FancyImageScreen.Header>
        <FancyImageScreen.Title>
          Now let's add some{" "}
          {
            {
              [CommunityType.Family]: "family",
              [CommunityType.CommunityCenter]: "community",
              [CommunityType.Other]: "",
            }[communityType]
          }{" "}
          members!
        </FancyImageScreen.Title>
        <FancyImageScreen.Subtitle>
          Invite people to join your new community.
        </FancyImageScreen.Subtitle>
      </FancyImageScreen.Header>
      <Stack>
        <IonButton
          onClick={() =>
            communityInviteModal.open({
              communityId,
              defaultEntryType: "contacts",
            })
          }
        >
          Invite with contacts
        </IonButton>
        <IonButton
          onClick={() =>
            communityInviteModal.open({
              communityId,
              defaultEntryType: "link",
            })
          }
        >
          Invite with link
        </IonButton>
      </Stack>
      <IonButton fill="clear" onClick={onComplete}>
        Skip for now
      </IonButton>
      {communityInviteModal.render()}
    </FancyImageScreen>
  );
}

export function CommunityOnboarding({
  onComplete,
}: {
  onComplete: () => Promise<unknown>;
}) {
  const [step, setStep] = useState<StepState>({ type: Step.Intro });
  const inviteModal = useInviteModal({ onAccept: onComplete });

  // prefetch invites
  trpc.community.getCommunityInvites.useQuery();

  const getStep = () => {
    const scanInviteAction = (
      <>
        <IonButton onClick={() => inviteModal.open()}>Scan Invite</IonButton>
        {inviteModal.render()}
      </>
    );
    switch (step.type) {
      case Step.Intro:
        return {
          action: scanInviteAction,
          render: () => (
            <StepIntro
              onComplete={() => {
                setStep({ type: Step.ViewInvites });
                if (window.location.pathname.includes("/invite/"))
                  inviteModal
                    .open(window.location.pathname.split("/").pop())
                    .catch(console.error);
              }}
            />
          ),
        };
      case Step.ViewInvites:
        return {
          action: scanInviteAction,
          onBack: () => setStep({ type: Step.Intro }),
          render: () => (
            <StepViewInvites
              onScanInvite={() => inviteModal.open()}
              onSkip={() => setStep({ type: Step.CreateCommunity_SetType })}
              onComplete={onComplete}
            />
          ),
        };
      case Step.CreateCommunity_SetType:
        return {
          action: scanInviteAction,
          onBack: () => setStep({ type: Step.ViewInvites }),
          render: () => (
            <StepCreateCommunitySetType
              onComplete={(type) =>
                setStep({
                  type: Step.CreateCommunity_SetName,
                  communityType: type,
                })
              }
            />
          ),
        };
      case Step.CreateCommunity_SetName:
        return {
          action: scanInviteAction,
          onBack: () => setStep({ type: Step.CreateCommunity_SetType }),
          render: () => (
            <StepCreateCommunitySetName
              communityType={step.communityType}
              onComplete={(communityId) =>
                setStep({
                  type: Step.CreateCommunity_Invite,
                  communityId,
                  communityType: step.communityType,
                })
              }
            />
          ),
        };
      case Step.CreateCommunity_Invite:
        return {
          render: () => (
            <StepCreateCommunityInvite
              communityId={step.communityId}
              communityType={step.communityType}
              onComplete={onComplete}
            />
          ),
        };
    }
  };
  const stepHandler = getStep();

  return (
    <PageFrame
      immersive
      header={
        <>
          {stepHandler.onBack ? (
            <IonButtons slot="start">
              <IonButton onClick={stepHandler.onBack}>Back</IonButton>
            </IonButtons>
          ) : null}
          {stepHandler.action ? (
            <IonButtons slot="end">{stepHandler.action}</IonButtons>
          ) : null}
        </>
      }
    >
      {stepHandler.render()}
    </PageFrame>
  );
}
