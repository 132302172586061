import { useAsyncCallback } from "react-async-hook";
import { FileSharer } from "@byteowls/capacitor-filesharer";

export function useShareImage() {
  return useAsyncCallback(async (url: string, filename: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const base64Data = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")?.[1] || ""); // Remove the Data URL part, keep only Base64 encoded string
      };
      reader.onerror = (error) => reject(error);
    });

    await FileSharer.share({
      filename,
      contentType: "image/jpeg",
      base64Data: base64Data,
    });
  });
}
