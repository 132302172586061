import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import { useIonRouter } from "@ionic/react";
import { NotificationData, NotificationType } from "@repo/shared";
import { useStore } from "jotai";

import { channelPushReceivedSubject, openChannelIdAtom } from "../state";
import { trpc } from "../trpc";
import { isWeb } from "../utils";
import { DeeplinkType, useAddDeeplinkAction } from "./useDeeplinkAction";
import { useOnMount } from "./useOnMount";

async function registerNotifications(
  onRegistration: (token: Token) => void,
  onNotificationActionPerformed: (data: unknown) => void,
  shouldDisplayLocalNotification: (data: unknown) => boolean
) {
  if (isWeb()) return;

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    console.error("Push notifications not granted");
    return;
  }
  await PushNotifications.addListener("registration", onRegistration);
  await PushNotifications.addListener("registrationError", (error) =>
    console.error("Error on push notification registration", error)
  );
  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) =>
      onNotificationActionPerformed(notification.notification.data)
  );
  await LocalNotifications.addListener(
    "localNotificationActionPerformed",
    (data) => onNotificationActionPerformed(data.notification.extra)
  );
  // forward received notification, that are received while app is open, to local notifications
  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) =>
      shouldDisplayLocalNotification(notification.data) &&
      LocalNotifications.schedule({
        notifications: [
          {
            id: Math.round(Date.now() / 1000),
            body: notification.body || "",
            title: notification.title || "",
            extra: notification.data,
            ongoing: false,
          },
        ],
      })
  );
  await PushNotifications.register();
}

export function useRegisterNotifications() {
  const router = useIonRouter();
  const store = useStore();
  const addDeeplinkAction = useAddDeeplinkAction();
  const registerPushTokenMutation = trpc.me.registerPushToken.useMutation();

  // Request permission to use push notifications
  useOnMount(() => {
    void registerNotifications(
      (token) => registerPushTokenMutation.mutate({ token: token.value }),
      (notificationData) => {
        const data = NotificationData.safeParse(notificationData);
        if (!data.success) {
          console.error("Invalid notification data", notificationData);
          return;
        }
        switch (data.data.type) {
          case NotificationType.NewChannelMessage:
            router.push("/home");
            addDeeplinkAction({
              type: DeeplinkType.OpenChannel,
              channelId: data.data.channelId,
            });
            break;
          case NotificationType.NewSnap:
            router.push(`/snaps`);
            addDeeplinkAction({
              type: DeeplinkType.OpenSnap,
              snapId: data.data.snapId,
            });
            break;
          case NotificationType.NewSnapComment:
            router.push(`/snaps`);
            addDeeplinkAction({
              type: DeeplinkType.OpenSnapComment,
              snapId: data.data.snapId,
              commentId: data.data.commentId,
            });
            break;
          case NotificationType.DailySnapReminder:
            router.push(`/snaps`);
            break;
          default:
            console.error("Unhandled notification type", data.data);
            data.data satisfies never;
        }
      },
      (data) => {
        const notificationData = NotificationData.safeParse(data);
        if (!notificationData.success) {
          console.error("Invalid notification data", data);
          return true; // don't block unsupported notifications, might be from a newer version
        }
        switch (notificationData.data.type) {
          case NotificationType.NewChannelMessage:
            if (
              store.get(openChannelIdAtom) === notificationData.data.channelId
            ) {
              // don't show notification if channel is currently open
              return false;
            }
            channelPushReceivedSubject.next(notificationData.data.channelId);
            return true;
          case NotificationType.NewSnap:
          case NotificationType.NewSnapComment:
          case NotificationType.DailySnapReminder:
            return true;
          default:
            return false;
        }
      }
    );
  }, [addDeeplinkAction, registerPushTokenMutation, router, store]);

  return { fcmToken: registerPushTokenMutation.variables?.token };
}
