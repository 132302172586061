import { IonButton, IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";

import { css } from "../../styled-system/css";

export function CardClose({ onClose }: { onClose: () => void }) {
  return (
    <IonButton
      className={css({ position: "absolute", top: 0, right: 0 })}
      fill="clear"
      onClick={onClose}
    >
      <IonIcon icon={close} />
    </IonButton>
  );
}
