import { IonBadge, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";

import { useModal } from "../lib/hooks/useModal";
import { trpc } from "../lib/trpc";
import { showActionSheet } from "../lib/utils";
import { LoadingButton } from "./LoadingButton";

export function useViewUsersModal() {
  const modal = useModal<{
    title: string;
    users: { id: string; name: string; phone: string | null }[];
  }>();
  const utils = trpc.useUtils();
  const blockedUsersQuery = trpc.me.getMyBlockedUsers.useQuery();
  const blockUserMutation = trpc.me.blockUser.useMutation({
    onSuccess: () => utils.me.getMyBlockedUsers.invalidate(),
  });
  const unblockUserMutation = trpc.me.unblockUser.useMutation({
    onSuccess: () => utils.me.getMyBlockedUsers.invalidate(),
  });

  return {
    open: modal.open,
    render: () =>
      modal.render(
        { title: modal.value?.title || "Users" },
        modal.value?.users.map((user) => (
          <IonItem key={user.id}>
            <IonLabel>
              {user.name
                ? `${user.name} (${user.phone})`
                : user.phone || "Unknown user"}
            </IonLabel>
            {blockedUsersQuery.data?.some(
              (blockedUser) => blockedUser.blockedId === user.id
            ) ? (
              <IonBadge color="danger">Blocked</IonBadge>
            ) : null}
            <LoadingButton
              fill="clear"
              isLoading={
                (blockUserMutation.isPending &&
                  blockUserMutation.variables.userId === user.id) ||
                (unblockUserMutation.isPending &&
                  unblockUserMutation.variables.userId === user.id)
              }
              size="small"
              onClick={() =>
                showActionSheet("User Actions", [
                  {
                    title: blockedUsersQuery.data?.some(
                      (blockedUser) => blockedUser.blockedId === user.id
                    )
                      ? "Unblock"
                      : "Block",
                    isDestructive: true,
                    onClick: () => {
                      if (
                        blockedUsersQuery.data?.some(
                          (blockedUser) => blockedUser.blockedId === user.id
                        )
                      ) {
                        unblockUserMutation.mutate({ userId: user.id });
                      } else {
                        blockUserMutation.mutate({ userId: user.id });
                      }
                    },
                  },
                ])
              }
            >
              <IonIcon icon={ellipsisVertical} />
            </LoadingButton>
          </IonItem>
        ))
      ),
  };
}
