import { z } from "zod";

export enum NotificationType {
  NewChannelMessage = "NewChannelMessage",
  NewSnap = "NewSnap",
  NewSnapComment = "NewSnapComment",
  DailySnapReminder = "DailySnapReminder",
}

export const NotificationData = z.union([
  z.object({
    type: z.literal(NotificationType.NewChannelMessage),
    channelId: z.string(),
    senderId: z.string(),
  }),
  z.object({
    type: z.literal(NotificationType.NewSnap),
    snapId: z.string(),
    senderId: z.string(),
  }),
  z.object({
    type: z.literal(NotificationType.NewSnapComment),
    snapId: z.string(),
    senderId: z.string(),
    commentId: z.string(),
  }),
  z.object({
    type: z.literal(NotificationType.DailySnapReminder),
    reminderUserId: z.string(),
  }),
]);
export type NotificationData = z.infer<typeof NotificationData>;

export const ReportedContentData = z.union([
  z.object({
    type: z.literal("channelMessage"),
    sid: z.string(),
    channelId: z.string(),
    authorName: z.string(),
    text: z.string(),
    imageSid: z.string().optional(),
  }),
  z.object({
    type: z.literal("snap"),
    id: z.string(),
    authorName: z.string(),
    imageUrl: z.string(),
    caption: z.string(),
  }),
  z.object({
    type: z.literal("snapComment"),
    id: z.string(),
    authorName: z.string(),
    text: z.string(),
  }),
]);
export type ReportedContentData = z.infer<typeof ReportedContentData>;

export const CommunityFeatureConfig = z.object({
  roles: z
    .object({
      enabled: z.boolean().optional(),
      memberPermissions: z
        .object({
          inviteToCommunity: z.boolean().optional(),
          removeFromCommunity: z.boolean().optional(),

          createChannel: z.boolean().optional(),
          editChannel: z.boolean().optional(),
          deleteChannel: z.boolean().optional(),
          inviteToChannel: z.boolean().optional(),
          removeFromChannel: z.boolean().optional(),
        })
        .optional(),
    })
    .optional(),
});
export type CommunityFeatureConfig = z.infer<typeof CommunityFeatureConfig>;

export enum CommunityType {
  Family = "family",
  CommunityCenter = "communityCenter",
  Other = "other",
}
