import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { env, isDev } from "../lib/config";

const postHogOptions: Partial<PostHogConfig> = {
  api_host: env.posthogHost,

  session_recording: {
    maskAllInputs: true,
    maskTextSelector: ".mask-me",
  },
};

export function PostHogProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  if (isDev) return <>{children}</>;

  return (
    <PostHogProvider apiKey={env.posthogApiKey} options={postHogOptions}>
      {children}
    </PostHogProvider>
  );
}
