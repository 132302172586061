import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Color } from "@ionic/core";
import { IonToast } from "@ionic/react";

interface ToastContextType {
  dispatch: (message: string, options?: { color: Color }) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context.dispatch;
};

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<
    {
      id: string;
      message: string;
      options?: Parameters<ToastContextType["dispatch"]>[1];
    }[]
  >([]);

  const dispatch = useCallback<ToastContextType["dispatch"]>(
    (message, options) => {
      setToasts((prevToasts) => [
        ...prevToasts,
        { id: `${Date.now()}`, message, options },
      ]);
    },
    []
  );

  const value = useMemo(() => ({ dispatch }), [dispatch]);

  return (
    <ToastContext.Provider value={value}>
      {toasts.map((toast) => (
        <IonToast
          key={toast.id}
          message={toast.message}
          duration={3000}
          isOpen
          color={toast.options?.color}
          onDidDismiss={() => {
            setToasts((prevToasts) =>
              prevToasts.filter((t) => t.id !== toast.id)
            );
          }}
        />
      ))}
      {children}
    </ToastContext.Provider>
  );
};
