import { IonSpinner } from "@ionic/react";

import { Flex } from "../../styled-system/jsx";

export function FullScreenLoader() {
  return (
    <Flex
      css={{ justifyContent: "center", alignItems: "center", height: "100vh" }}
    >
      <IonSpinner
        name="crescent"
        color="primary"
        style={{ width: 100, height: 100 }}
      />
    </Flex>
  );
}
