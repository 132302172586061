import { useRef, useState } from "react";
import { IonTextarea } from "@ionic/react";
import { ReportedContentData } from "@repo/shared";

import { css } from "../../styled-system/css";
import { styled } from "../../styled-system/jsx";
import { useModal } from "../lib/hooks/useModal";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";
import { LoadingButton } from "./LoadingButton";

const typeLabels: Record<ReportedContentData["type"], string> = {
  channelMessage: "Channel Message",
  snap: "Photo",
  snapComment: "Photo Comment",
};

export function useReportModal() {
  const toast = useToast();
  const modal = useModal<ReportedContentData>();
  const reportContentMutation = trpc.me.reportContent.useMutation({
    onSuccess: () => {
      modal.close();
      toast(
        "Thank you for reporting this content. We will review it and take action as soon as possible.",
        { color: "success" }
      );
    },
  });

  const [reason, setReason] = useState("");
  const reasonRef = useRef<HTMLIonTextareaElement>(null);

  return {
    open: modal.open,
    render: () =>
      modal.render(
        { title: "Report", onDidPresent: () => reasonRef.current?.setFocus() },
        (data) => (
          <>
            <h2>Report an issue with this content</h2>
            <styled.div
              css={{
                padding: "20px",
                backgroundColor: "#f8f9fa",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <styled.h3 css={{ marginBottom: "10px" }}>
                Content Details
              </styled.h3>
              <styled.p
                css={{ fontSize: "16px", color: "#333", marginBottom: "5px" }}
              >
                Type: <span className="mask-me">{typeLabels[data.type]}</span>
              </styled.p>
              <styled.p
                css={{ fontSize: "16px", color: "#333", marginBottom: "15px" }}
              >
                Author: <span className="mask-me">{data.authorName}</span>
              </styled.p>
              {data.type === "snap" && (
                <>
                  <styled.img
                    src={data.imageUrl}
                    alt="Reported Content"
                    className="ph-no-capture"
                    css={{ maxWidth: "100%", borderRadius: "4px" }}
                  />
                  <styled.p css={{ fontSize: "16px", color: "#333" }}>
                    Caption:{" "}
                    <span className="mask-me">
                      {data.caption || <i>Empty</i>}
                    </span>
                  </styled.p>
                </>
              )}
              {data.type === "channelMessage" && (
                <>
                  <styled.p css={{ fontSize: "16px", color: "#333" }}>
                    Message:{" "}
                    <span className="mask-me">{data.text || <i>Empty</i>}</span>
                  </styled.p>
                  {data.imageSid && "1 Attached Image"}
                </>
              )}
              {data.type === "snapComment" && (
                <styled.p css={{ fontSize: "16px", color: "#333" }}>
                  Comment:{" "}
                  <span className="mask-me">{data.text || <i>Empty</i>}</span>
                </styled.p>
              )}
            </styled.div>
            <IonTextarea
              ref={reasonRef}
              placeholder="Please describe the issue"
              autoGrow
              rows={3}
              value={reason}
              onIonInput={(e) => setReason(e.detail.value!)}
            />
            <LoadingButton
              className={css({ mt: 16 })}
              expand="full"
              isLoading={reportContentMutation.isPending}
              onClick={() => {
                reportContentMutation.mutate({ reason, content: data });
              }}
            >
              Submit
            </LoadingButton>
          </>
        )
      ),
  };
}
