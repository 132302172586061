import { createRoot } from "react-dom/client";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { setupIonicReact } from "@ionic/react";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";

import { PostHogProviderWrapper } from "./components/PostHogProviderWrapper";
import { isDev } from "./lib/config";
import { App } from "./App";
import "./main.css";

setupIonicReact();
defineCustomElements(window).catch(console.error);

if (!isDev) {
  Sentry.init(
    {
      dsn: "https://a780a5fcd76f007402d28cb31de9aa24@o4507188056227840.ingest.us.sentry.io/4507188082311168",
      integrations: [
        new SentryReact.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/,
          ],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
    },
    // Forward the init method from @sentry/react
    SentryReact.init
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <PostHogProviderWrapper>
    <App />
  </PostHogProviderWrapper>
);
