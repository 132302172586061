import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useIonRouter } from "@ionic/react";

export function useAppUrlRegister() {
  const router = useIonRouter();
  const routerPush = router.push;
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split("app.bridgesocial.io").pop();
      if (slug) {
        routerPush(slug, "forward");
        console.log("Opened url", slug, event.url, event);
      }
    }).catch(console.error);
  }, [routerPush]);
}
