import { useEffect, useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import QRCode from "react-qr-code";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { Contacts } from "@capacitor-community/contacts";
import {
  IonBadge,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import { isDefined, zPhone } from "@repo/shared";
import Fuse from "fuse.js";
import { search } from "ionicons/icons";
import { uniqBy } from "lodash";
import { VList } from "virtua";

import { css } from "../../styled-system/css";
import { Flex, Stack } from "../../styled-system/jsx";
import { MutedDesc } from "../components/base";
import { formatError } from "../lib/err";
import { useModal } from "../lib/hooks/useModal";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";
import { LoadingButton } from "./LoadingButton";

export function useCommunityInviteModal({
  onClose,
}: {
  onClose?: () => void;
} = {}) {
  const toast = useToast();
  const modal = useModal<{
    communityId: string;
    defaultEntryType?: "link" | "contacts";
  }>({ onClose });
  const communityQuery = trpc.community.getCommunity.useQuery(
    { communityId: modal.value?.communityId! },
    { enabled: !!modal.value?.communityId }
  );

  const createCommunityInviteLinkMutation =
    trpc.community.createCommunityInviteLink.useMutation();
  const createCommunityInviteLinkReset =
    createCommunityInviteLinkMutation.reset;
  const createCommunityInviteLinkMutate =
    createCommunityInviteLinkMutation.mutate;
  useEffect(() => {
    if (!modal.value?.communityId) {
      createCommunityInviteLinkReset();
      return;
    }
    createCommunityInviteLinkMutate({
      communityId: modal.value?.communityId,
    });
  }, [
    modal.value?.communityId,
    createCommunityInviteLinkReset,
    createCommunityInviteLinkMutate,
  ]);

  const createCommunityInviteMutation =
    trpc.community.createCommunityInvite.useMutation();
  const readContacts = useAsyncCallback(async () => {
    const result = await Contacts.getContacts({
      projection: { name: true, phones: true },
    });
    return result.contacts;
  });

  const [entryType, setEntryType] = useState<"link" | "contacts">("link");
  useEffect(() => {
    if (modal.value?.defaultEntryType)
      setEntryType(modal.value.defaultEntryType);
  }, [modal.value?.defaultEntryType]);
  const readContactsExecute = readContacts.execute;
  useEffect(() => {
    if (entryType === "contacts") readContactsExecute().catch(console.error);
  }, [entryType, readContactsExecute]);

  const [showAllContacts, setShowAllContacts] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // Fuzzy search contacts
  const filteredContacts = useMemo(() => {
    let contacts = (readContacts.result || []).map((contact) => ({
      contactId: contact.contactId,
      name: contact.name?.display ?? null,
      phones: (contact.phones || [])
        .map((phone) =>
          phone.number ? { number: phone.number, type: phone.type } : null
        )
        .filter(isDefined),
    }));

    if (!showAllContacts) {
      contacts = contacts
        .map((contact) => ({
          ...contact,
          phones: uniqBy(
            contact.phones
              .map((phone) => {
                const zPhoneResult = zPhone.safeParse(phone.number);
                return zPhoneResult.success
                  ? { number: zPhoneResult.data, type: phone.type }
                  : null;
              })
              .filter(isDefined),
            (phone) => phone.number
          ),
        }))
        .filter((contact) => contact.phones.length > 0);
    }

    const fuse = new Fuse(contacts, {
      keys: ["name", "phones.number"],
      includeScore: true,
      threshold: 0.3,
    });
    const results = searchTerm
      ? fuse.search(searchTerm).map((result) => result.item)
      : contacts;

    return results.sort((a, b) =>
      (a.name || "Unknown")
        .toLowerCase()
        .localeCompare((b.name || "Unknown").toLowerCase())
    );
  }, [searchTerm, readContacts.result, showAllContacts]);

  return {
    open: modal.open,
    render: () =>
      modal.render(
        {
          title: `Invite to ${communityQuery.data?.name ?? "Community"}`,
        },
        <Stack css={{ minHeight: "100%", gap: 0 }}>
          <IonSegment
            value={entryType}
            onIonChange={(e) =>
              setEntryType(e.detail.value as "link" | "contacts")
            }
          >
            <IonSegmentButton value="link">
              <IonLabel>Link</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="contacts">
              <IonLabel>Contacts</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {entryType === "link" ? (
            <Stack css={{ mx: 5, mt: 5, alignItems: "center", gap: 5 }}>
              <MutedDesc>
                Invite friends or family members to your community with this
                link
              </MutedDesc>
              <IonTextarea
                placeholder="Loading..."
                className={css({ textAlign: "center" })}
                value={createCommunityInviteLinkMutation.data?.url ?? ""}
              />
              <Flex>
                <IonButton
                  fill="clear"
                  disabled={!createCommunityInviteLinkMutation.data?.url}
                  onClick={() => {
                    Clipboard.write({
                      string: createCommunityInviteLinkMutation.data?.url!,
                    }).catch(console.error);
                    toast("Copied to clipboard", {
                      color: "success",
                    });
                  }}
                >
                  Copy
                </IonButton>
                <IonButton
                  fill="clear"
                  disabled={!createCommunityInviteLinkMutation.data?.url}
                  onClick={() =>
                    Share.share({
                      text: `Join me on Bridge Social! ${createCommunityInviteLinkMutation.data?.url}`,
                    })
                  }
                >
                  Share
                </IonButton>
              </Flex>
              {createCommunityInviteLinkMutation.isPending ? (
                <IonSpinner />
              ) : (
                <QRCode
                  value={createCommunityInviteLinkMutation.data?.url ?? ""}
                />
              )}
            </Stack>
          ) : readContacts.loading ? (
            <Flex
              css={{
                w: "100%",
                mt: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IonSpinner name="crescent" />
            </Flex>
          ) : (
            <Stack css={{ flex: 1 }}>
              <Flex css={{ mx: 5, alignItems: "center" }}>
                <IonInput
                  placeholder="Search contacts"
                  value={searchTerm}
                  onIonInput={(e) => setSearchTerm(e.detail.value!)}
                >
                  <IonIcon slot="start" icon={search} aria-hidden="true" />
                  <IonCheckbox
                    slot="end"
                    checked={showAllContacts}
                    onIonChange={(e) => setShowAllContacts(e.detail.checked)}
                  >
                    Show all
                  </IonCheckbox>
                </IonInput>
              </Flex>
              {!filteredContacts?.length && (
                <MutedDesc>No contacts found</MutedDesc>
              )}
              <Stack css={{ gap: 0, flex: 1 }}>
                <VList className={css({ flex: 1 })}>
                  {filteredContacts.map((contact) => (
                    <IonItem key={contact.contactId}>
                      <Stack css={{ mt: 2, gap: 0, w: "100%" }}>
                        <IonLabel>{contact.name || <i>Unknown</i>}</IonLabel>
                        <IonList>
                          {(contact.phones || []).map((phone, index) => {
                            const alreadyPresent =
                              communityQuery.data?.communityUsers.some(
                                (cu) => cu.user.phone === phone.number
                              );
                            return (
                              <IonItem key={index}>
                                <IonBadge slot="start" color="light">
                                  {phone.type}
                                </IonBadge>
                                {phone.number}
                                <LoadingButton
                                  slot="end"
                                  disabled={
                                    createCommunityInviteMutation.isPending ||
                                    alreadyPresent
                                  }
                                  isLoading={
                                    createCommunityInviteMutation.isPending &&
                                    createCommunityInviteMutation.variables
                                      ?.phone === phone.number
                                  }
                                  onClick={() => {
                                    createCommunityInviteMutation.mutate(
                                      {
                                        phone: phone.number,
                                        communityId: modal.value?.communityId!,
                                      },
                                      {
                                        onSuccess: () =>
                                          void Share.share({
                                            text: `Join me on Bridge Social! https://app.bridgesocial.io/`,
                                          }),
                                        onError: (error) => {
                                          if (
                                            // lm_c0e02d85e9 check if user is already invited
                                            error.message.includes(
                                              "already invited"
                                            )
                                          ) {
                                            void Share.share({
                                              text: `Join me on Bridge Social! https://app.bridgesocial.io/`,
                                            });
                                          } else {
                                            toast(formatError(error), {
                                              color: "danger",
                                            });
                                          }
                                        },
                                      }
                                    );
                                  }}
                                >
                                  {alreadyPresent
                                    ? "In Community"
                                    : "Send Invite"}
                                </LoadingButton>
                              </IonItem>
                            );
                          })}
                        </IonList>
                      </Stack>
                    </IonItem>
                  ))}
                </VList>
              </Stack>
            </Stack>
          )}
        </Stack>
      ),
  };
}
