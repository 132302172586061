import { useCallback, useEffect, useRef, useState } from "react";
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { can } from "@repo/shared";
import { ellipsisVertical } from "ionicons/icons";
import plimit from "p-limit";
import { VList } from "virtua";

import { css } from "../../styled-system/css";
import { Flex, styled } from "../../styled-system/jsx";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { useCommunityInviteModal } from "../components/useCommunityInviteModal";
import { useViewUsersModal } from "../components/useViewUsersModal";
import {
  DeeplinkType,
  useDeeplinkAction,
} from "../lib/hooks/useDeeplinkAction";
import { useLocalStorage } from "../lib/hooks/useLocalStorage";
import { useObservableCallback } from "../lib/hooks/useObservableCallback";
import { useToast } from "../lib/hooks/useToast";
import { useTwilioConversationClient } from "../lib/hooks/useTwilioConversationClient";
import { useUpdatingRef } from "../lib/hooks/useUpdatingRef";
import { channelPushReceivedSubject } from "../lib/state";
import { trpc } from "../lib/trpc";
import { showActionSheet } from "../lib/utils";
import { useChannelModal } from "./ChannelPage";

const channelColors = [
  "linear-gradient(135deg, #97ABFF 10%, #123597 100%)",
  "linear-gradient(135deg, #81FBB8 10%, #28C76F 100%)",
  "linear-gradient(135deg, #FCCF31 10%, #F55555 100%)",
  "linear-gradient(135deg, #FEB692 10%, #EA5455 100%)",
  "linear-gradient(135deg, #E2B0FF 10%, #9F44D3 100%)",
  "linear-gradient(135deg, #A0FE65 10%, #FA016D 100%)",
  "linear-gradient(135deg, #EECE13 10%, #B210FF 100%)",
  "linear-gradient(135deg, #5EFCE8 10%, #736EFE 100%)",
  "linear-gradient(135deg, #43CBFF 10%, #9708CC 100%)",
  "linear-gradient(135deg, #F05F57 10%, #360940 100%)",
];

function useCreateChannelModal(
  onOpenChannel: (c: { channelId: string; channelName: string }) => void
) {
  const utils = trpc.useUtils();
  const toast = useToast();
  const inputRef = useRef<HTMLIonInputElement>(null);
  const [showCreateChannelModal, setShowCreateChannelModal] = useState<{
    communityId: string;
    communityName: string;
  } | null>(null);
  const createChannelMutation = trpc.channel.createChannel.useMutation({
    onSuccess: (data) => {
      toast("Chat created", { color: "success" });
      setShowCreateChannelModal(null);
      void utils.channel.getChannels.invalidate({
        communityId: data.communityId,
      });
      onOpenChannel({ channelId: data.id, channelName: data.name });
    },
  });

  const [name, setName] = useState("");

  return {
    renderCreateChannelModal: () => (
      <IonModal
        isOpen={!!showCreateChannelModal}
        onDidPresent={() => inputRef.current?.setFocus()}
        onDidDismiss={() => setShowCreateChannelModal(null)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setShowCreateChannelModal(null)}>
                Back
              </IonButton>
            </IonButtons>
            <IonTitle>
              New Chat for{" "}
              {showCreateChannelModal?.communityName || "Community"}
            </IonTitle>
            <IonButtons slot="end">
              <LoadingButton
                isLoading={createChannelMutation.isPending}
                onClick={() => {
                  createChannelMutation.mutate({
                    name,
                    communityId: showCreateChannelModal!.communityId,
                  });
                }}
              >
                Create
              </LoadingButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonInput
            ref={inputRef}
            className={css({ mt: 16 })}
            label="Chat Name"
            autoFocus
            autocapitalize="words"
            spellcheck
            value={name}
            onIonInput={(e) => setName(e.detail.value!)}
          />
        </IonContent>
      </IonModal>
    ),
    showCreateChannelModal: (v: typeof showCreateChannelModal) =>
      setShowCreateChannelModal(v),
  };
}

export function HomePage() {
  const { data: me } = trpc.me.getMe.useQuery();
  const [communities, communitiesQuery] =
    trpc.community.getCommunities.useSuspenseQuery();
  const communitiesRef = useUpdatingRef(communities);
  const { convoClient } = useTwilioConversationClient();

  const [unreadCounts, setUnreadCounts] = useLocalStorage<
    Record<string, number>
  >("unreadCounts", {}); // map of channel id to unread count
  const unreadCountsRef = useRef(unreadCounts);

  const updateUnreadCount = useCallback(
    async (channelId: string, cancelledRef: { current: boolean }) => {
      if (cancelledRef.current || !convoClient) return;

      const channel = communitiesRef.current
        .flatMap((c) => c.channels)
        .find((c) => c.id === channelId);
      if (!channel) return;
      const conversation = await convoClient.getConversationBySid(
        channel.externalId
      );
      const unreadCount =
        (await conversation.getUnreadMessagesCount()) ??
        (await conversation.getMessagesCount());

      if (cancelledRef.current) return;
      unreadCountsRef.current[channelId] = unreadCount;
      setUnreadCounts({ ...unreadCountsRef.current });
    },
    [communitiesRef, convoClient, setUnreadCounts]
  );

  useEffect(() => {
    const cancelledRef = { current: false };
    if (!convoClient) return;

    (async () => {
      const channels = communities.flatMap((c) => c.channels);
      const limit = plimit(5);

      const fetchUnreadCounts = channels.map((channel) =>
        limit(() => updateUnreadCount(channel.id, cancelledRef))
      );
      await Promise.all(fetchUnreadCounts);
    })().catch(console.error);

    return () => {
      cancelledRef.current = true;
    };
  }, [
    convoClient,
    communities,
    communitiesQuery.dataUpdatedAt,
    updateUnreadCount,
  ]);

  useObservableCallback(channelPushReceivedSubject, (channelId) => {
    updateUnreadCount(channelId, { current: false }).catch(console.error);
  });

  const channelModal = useChannelModal();
  useDeeplinkAction((action) => {
    if (action.data.type === DeeplinkType.OpenChannel) {
      channelModal.open({
        channelId: action.data.channelId,
        channelName: undefined,
      });
      return true;
    }
    return false;
  });

  const viewUsersModal = useViewUsersModal();
  const { renderCreateChannelModal, showCreateChannelModal } =
    useCreateChannelModal(channelModal.open);

  const [collapsedCommunityIds, setCollapsedCommunityIds] = useLocalStorage<
    string[]
  >("collapsedCommunityIds", []);

  const communityInviteModal = useCommunityInviteModal();

  let channelCounter = 0;

  return (
    <PageFrame immersive scrollY={false}>
      <IonRefresher
        className={css({ marginTop: "env(safe-area-inset-top)" })}
        slot="fixed"
        onIonRefresh={(e) =>
          communitiesQuery.refetch().finally(() => e.detail.complete())
        }
      >
        <IonRefresherContent />
      </IonRefresher>
      {renderCreateChannelModal()}
      {channelModal.render()}
      {viewUsersModal.render()}
      {communityInviteModal.render()}
      <VList className="ion-content-scroll-host">
        <styled.div css={{ height: "env(safe-area-inset-top)" }} />
        {communities.map((community) => (
          <IonRow key={community.id}>
            <IonCol>
              <Flex css={{ justifyContent: "space-between", ml: 4 }}>
                <IonText>
                  <h3>{community.name}</h3>
                </IonText>
                <IonButton
                  fill="clear"
                  onClick={() =>
                    showActionSheet("Community Actions", [
                      {
                        title: "View Members",
                        onClick: () =>
                          viewUsersModal.open({
                            title: `${community.name} Members`,
                            users: community.communityUsers.map((p) => p.user),
                          }),
                      },
                      ...(can("inviteToCommunity", { user: me, community })
                        ? [
                            {
                              title: "Invite Members",
                              onClick: () => {
                                communityInviteModal.open({
                                  communityId: community.id,
                                });
                              },
                            },
                          ]
                        : []),
                      ...(can("createChannel", { user: me, community })
                        ? [
                            {
                              title: "Create Chat",
                              onClick: () =>
                                showCreateChannelModal({
                                  communityId: community.id,
                                  communityName: community.name,
                                }),
                            },
                          ]
                        : []),
                      {
                        title: collapsedCommunityIds.includes(community.id)
                          ? "Show Chats"
                          : "Hide Chats",
                        onClick: () =>
                          setCollapsedCommunityIds((ids) =>
                            ids.includes(community.id)
                              ? ids.filter((id) => id !== community.id)
                              : [...ids, community.id]
                          ),
                      },
                    ])
                  }
                >
                  <IonIcon icon={ellipsisVertical} />
                </IonButton>
              </Flex>
              {collapsedCommunityIds.includes(community.id)
                ? null
                : community.channels.map((channel) => (
                    <IonCard
                      key={channel.id}
                      button
                      onClick={() => {
                        channelModal.open({
                          channelId: channel.id,
                          channelName: channel.name,
                        });
                        unreadCountsRef.current[channel.id] = 0;
                        setUnreadCounts({ ...unreadCountsRef.current });
                      }}
                    >
                      <IonCardHeader
                        className={css({
                          py: 12,
                          textShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)",
                        })}
                        style={{
                          backgroundImage:
                            channelColors[
                              channelCounter++ % channelColors.length
                            ],
                        }}
                      >
                        <IonCardTitle
                          className={css({
                            color: "white",
                            textAlign: "center",
                          })}
                        >
                          {channel.name}
                          {unreadCounts[channel.id] ? (
                            <IonBadge
                              color="danger"
                              style={{ marginLeft: 8, fontSize: 12 }}
                            >
                              {unreadCounts[channel.id]}
                            </IonBadge>
                          ) : null}
                        </IonCardTitle>
                      </IonCardHeader>
                    </IonCard>
                  ))}
            </IonCol>
          </IonRow>
        ))}
      </VList>
    </PageFrame>
  );
}
