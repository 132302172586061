import { useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import VerificationInput from "react-verification-input";
import { IonButton, IonInput } from "@ionic/react";

import { css } from "../../styled-system/css";
import { Flex } from "../../styled-system/jsx";
import bg1 from "../assets/onboarding-bg1.png";
import { MutedDesc } from "../components/base";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { useBlockUpdate } from "../components/UpdateProvider";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";
import { onEnter } from "../lib/utils";

enum AuthStep {
  Intro,
  EnterPhoneNumber,
  VerifySMSCode,
}

export function AuthPage({
  onLogin,
}: {
  onLogin: (authToken: string) => Promise<unknown>;
}) {
  const [step, setStep] = useState(AuthStep.Intro);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const onLoginAsync = useAsyncCallback(onLogin);
  const toast = useToast();

  useBlockUpdate(); // prevent updates during auth flow

  const requestLoginMutation = trpc.auth.requestLogin.useMutation({
    onSuccess: () => {
      setStep(AuthStep.VerifySMSCode); // Move to SMS verification step on successful phone number submission
    },
  });
  const requestLogin = () =>
    requestLoginMutation.mutateAsync({ phone: phoneNumber });

  const validateLoginMutation = trpc.auth.validateLogin.useMutation({
    onSuccess: (data) =>
      onLoginAsync.execute(data.authToken).catch(console.error),
  });
  const validateLogin = () =>
    validateLoginMutation.mutate({
      code: smsCode,
      phone: phoneNumber,
    });

  const resendCode = async () => {
    try {
      await requestLogin();
      toast("Verification code resent", { color: "success" });
    } catch (error) {
      console.error("Failed to resend code", error);
      toast("Failed to resend code", { color: "danger" });
    }
  };

  const goBackToPhoneNumber = () => {
    setStep(AuthStep.EnterPhoneNumber);
    setSmsCode("");
  };

  function renderStep() {
    switch (step) {
      case AuthStep.Intro:
        return (
          <FancyImageScreen coverImage={bg1} showLogo>
            <FancyImageScreen.Title>Welcome to bridge</FancyImageScreen.Title>
            <MutedDesc>
              Bridge helps you stay connected, so you can focus on what matters
              most.
            </MutedDesc>
            <IonButton
              expand="block"
              onClick={() => setStep(AuthStep.EnterPhoneNumber)}
            >
              Get Started
            </IonButton>
          </FancyImageScreen>
        );
      case AuthStep.EnterPhoneNumber:
        return (
          <FancyImageScreen coverImage={bg1} showLogo>
            <FancyImageScreen.Title>
              What's your phone number?
            </FancyImageScreen.Title>
            <IonInput
              className={css({
                mt: 8,
                mx: "auto",
                fontSize: "32px !important",
                fontFamily: "monospace !important",
              })}
              style={{
                width: `${Math.max(12, phoneNumber.length)}ch`,
                maxWidth: "90vw",
              }}
              placeholder="201-555-0123"
              type="tel"
              autofocus
              value={phoneNumber}
              onIonInput={(e) => setPhoneNumber(e.detail.value!)}
            />
            <MutedDesc css={{ mt: 10 }}>
              By continuing, you agree to our{" "}
              <a
                href="https://app.termly.io/document/terms-of-service/2738a135-5a36-489d-91d1-3b7dad3008bc"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://app.termly.io/document/privacy-policy/0ae89d88-001d-41d8-b88b-aa668365d105"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </MutedDesc>
            <LoadingButton
              onClick={requestLogin}
              isLoading={requestLoginMutation.isPending}
            >
              Login
            </LoadingButton>
          </FancyImageScreen>
        );
      case AuthStep.VerifySMSCode:
        return (
          <FancyImageScreen coverImage={bg1}>
            <FancyImageScreen.Title>
              Enter Verification Code
            </FancyImageScreen.Title>
            <MutedDesc>
              We sent a verification code to {phoneNumber}. Please enter it
              below.
            </MutedDesc>
            <Flex css={{ alignItems: "center", justifyContent: "center" }}>
              <VerificationInput
                autoFocus
                value={smsCode}
                onChange={setSmsCode}
                inputProps={{
                  type: "tel",
                  onKeyDown: onEnter(validateLogin),
                }}
              />
            </Flex>
            <LoadingButton
              className={css({ mt: 10 })}
              onClick={validateLogin}
              isLoading={
                validateLoginMutation.isPending || onLoginAsync.loading
              }
            >
              Verify
            </LoadingButton>
            <Flex css={{ justifyContent: "space-between" }}>
              <IonButton
                onClick={goBackToPhoneNumber}
                fill="clear"
                className={css({ mt: 2 })}
              >
                Back
              </IonButton>
              <LoadingButton
                onClick={resendCode}
                fill="clear"
                isLoading={requestLoginMutation.isPending}
              >
                Resend
              </LoadingButton>
            </Flex>
          </FancyImageScreen>
        );
    }
  }

  return <PageFrame immersive>{renderStep()}</PageFrame>;
}
