const { VITE_ENVIRONMENT } = import.meta.env;

const staging = {
  apiUrl: "https://backend.bridgesocial.io",
  posthogApiKey: "phc_aAFULB27Yirfd0z3Blp4xzTC7e35ECejFdZhOmSOOZy",
  posthogHost: "https://us.i.posthog.com",
};

export const env = {
  development: {
    ...staging,
    apiUrl: "http://192.168.1.69:4000",
  },
  staging,
}[VITE_ENVIRONMENT as string]!;

if (!env) throw new Error(`Unknown environment: ${VITE_ENVIRONMENT}`);

export const isDev = import.meta.env.DEV;
