export function throwError(message?: string): never {
  throw new Error(message || "Unexpected error");
}

export function throwNever(value: never): never {
  throw new Error(`Unexpected value: ${value}`);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}
