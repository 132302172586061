import { useEffect, useMemo, useRef, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";

import { css } from "../../styled-system/css";
import { Flex, styled } from "../../styled-system/jsx";
import bg1 from "../assets/snaps-bg1.png";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";
import { generateBase64ImageUrl } from "../lib/utils";

export function useTakeSnap({
  onSnapTaken,
}: {
  onSnapTaken: () => Promise<unknown>;
}) {
  const toast = useToast();
  const onSnapTakenAsync = useAsyncCallback(onSnapTaken);
  const meQuery = trpc.me.getMe.useQuery();
  const [communities] = trpc.community.getCommunities.useSuspenseQuery();
  const generateUploadUrlMutation = trpc.me.generateUploadUrl.useMutation();

  const takePicture = useAsyncCallback(() =>
    Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      direction: CameraDirection.Front,
      resultType: CameraResultType.Base64,
    })
  );
  const takenPictureBase64Url = useMemo(
    () => generateBase64ImageUrl(takePicture.result?.base64String),
    [takePicture.result]
  );
  const pictureUploaded = useAsync(
    async (imageUrl: string | undefined) => {
      if (!imageUrl) return null;

      const imageBlob = await (await fetch(imageUrl)).blob();
      const { url, key } = await generateUploadUrlMutation.mutateAsync({
        type: "snap",
      });
      try {
        const formData = new FormData();
        formData.append(
          "file",
          imageBlob,
          `snap-${meQuery.data?.id}-${new Date().toISOString().replace(/[.:-]/g, "")}.jpeg`
        );

        const response = await fetch(url, { method: "POST", body: formData });
        if (response.ok) {
          console.log("Image uploaded successfully");
        } else {
          console.error("Image upload failed", response);
          throw new Error("Image upload failed");
        }
        return key;
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    },
    [takenPictureBase64Url]
  );

  const [caption, setCaption] = useState("");
  const submitSnapMutation = trpc.snap.submitSnap.useMutation({
    onSuccess: async () => {
      toast("Photo submitted successfully", { color: "success" });
      setCaption("");
      try {
        await onSnapTakenAsync.execute();
      } catch (error) {
        console.error(error);
      } finally {
        takePicture.reset();
      }
    },
  });

  const selectRef = useRef<HTMLIonSelectElement | null>(null);
  const [selectedCommunities, setSelectedCommunities] = useState(
    communities.map((c) => c.id)
  );
  useEffect(() => {
    setSelectedCommunities((selectedCommunities) =>
      selectedCommunities.length
        ? selectedCommunities
        : communities.map((c) => c.id)
    );
  }, [communities]);

  const render = () => {
    return (
      <PageFrame immersive>
        <FancyImageScreen coverImage={bg1}>
          <div style={{ position: "relative" }}>
            <img
              className="ph-no-capture"
              alt="Taken Picture"
              src={takenPictureBase64Url}
            />
            {pictureUploaded.loading && (
              <styled.div
                css={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IonSpinner
                  name="crescent"
                  color="light"
                  style={{ width: 50, height: 50 }}
                />
              </styled.div>
            )}
          </div>
          <IonTextarea
            autofocus
            value={caption}
            autocapitalize="sentences"
            spellcheck
            autoGrow
            rows={1}
            wrap="soft"
            onIonInput={(e) => setCaption(e.detail.value!)}
            onIonFocus={(e) => setTimeout(() => e.target.scrollIntoView(), 500)}
            placeholder="Enter a caption (optional)"
          />
          <IonSelect
            ref={selectRef}
            className={css({ display: "none" })}
            interfaceOptions={{ header: "Select Communities" }}
            multiple={true}
            value={selectedCommunities}
            onIonChange={(e) => setSelectedCommunities(e.detail.value)}
          >
            {communities.map((community) => (
              <IonSelectOption key={community.id} value={community.id}>
                {community.name}
              </IonSelectOption>
            ))}
          </IonSelect>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <IonButton fill="clear" onClick={() => takePicture.execute()}>
              Retake
            </IonButton>
            <Flex>
              <IonButton fill="clear" onClick={() => selectRef.current?.open()}>
                <IonIcon icon={ellipsisVertical} />
              </IonButton>
              {pictureUploaded.error ? (
                <styled.p css={{ color: "red" }}>
                  Error uploading image
                </styled.p>
              ) : (
                <LoadingButton
                  disabled={
                    !pictureUploaded.result || !selectedCommunities.length
                  }
                  isLoading={
                    submitSnapMutation.isPending || onSnapTakenAsync.loading
                  }
                  onClick={() =>
                    submitSnapMutation.mutate({
                      imageSrc: pictureUploaded.result!,
                      caption,
                      communityIds: selectedCommunities,
                    })
                  }
                >
                  Post to {selectedCommunities.length}{" "}
                  {selectedCommunities.length !== 1
                    ? "communities"
                    : "community"}
                </LoadingButton>
              )}
            </Flex>
          </div>
        </FancyImageScreen>
      </PageFrame>
    );
  };

  return {
    shouldRender: !!takenPictureBase64Url,
    render,
    execute: takePicture.execute,
  };
}
