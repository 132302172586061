import { createContext, useCallback, useContext, useMemo } from "react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { httpBatchLink, loggerLink, TRPCClientError } from "@trpc/client";
import SuperJSON from "superjson";

import { env } from "../lib/config";
import { formatError } from "../lib/err";
import { useLocalStorage } from "../lib/hooks/useLocalStorage";
import { useToast } from "../lib/hooks/useToast";
import { useUpdatingRef } from "../lib/hooks/useUpdatingRef";
import { trpc } from "../lib/trpc";

const AuthContext = createContext<
  { hasToken: boolean; setToken: (token: string | null) => void } | undefined
>(undefined);

export function ApiProvider(props: { children: React.ReactNode }) {
  const toast = useToast();
  const [authToken, setAuthTokenImpl] = useLocalStorage<string | null>(
    "token",
    null
  );
  const authTokenRef = useUpdatingRef(authToken);
  const setAuthToken = useCallback(
    (token: string | null) => {
      setAuthTokenImpl(token);
      authTokenRef.current = token;
    },
    [authTokenRef, setAuthTokenImpl]
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: (error) => toast(formatError(error), { color: "danger" }),
          },
          queries: {
            retry: (failureCount: number, error: unknown) => {
              if (error instanceof TRPCClientError) {
                const nonRetryableCodes = new Set([
                  "BAD_REQUEST",
                  "UNAUTHORIZED",
                  "FORBIDDEN",
                  "NOT_FOUND",
                ]);

                if (nonRetryableCodes.has(error.data?.code)) {
                  return false;
                }
              }

              return failureCount < 3;
            },
          },
        },
        queryCache: new QueryCache({
          onError: (error) => toast(formatError(error), { color: "danger" }),
        }),
      }),
    [toast]
  );
  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        links: [
          loggerLink({
            enabled: (opts) =>
              process.env.NODE_ENV === "development" ||
              (opts.direction === "down" && opts.result instanceof Error),
            colorMode: "ansi",
          }),
          httpBatchLink({
            url: `${env.apiUrl}/trpc`,
            transformer: SuperJSON,
            async headers() {
              const token = authTokenRef.current;
              return token ? { Authorization: `Bearer ${token}` } : {};
            },
          }),
        ],
      }),
    [authTokenRef]
  );

  const authContext = useMemo(
    () => ({ hasToken: !!authToken, setToken: setAuthToken }),
    [authToken, setAuthToken]
  );

  return (
    <AuthContext.Provider value={authContext}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {props.children}
        </QueryClientProvider>
      </trpc.Provider>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
}
