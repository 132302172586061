import { useCallback } from "react";
import { useAsyncCallback } from "react-async-hook";
import { RouteComponentProps } from "react-router";
import {
  BarcodeFormat,
  BarcodeScanner,
  LensFacing,
} from "@capacitor-mlkit/barcode-scanning";
import { IonButton, IonButtons } from "@ionic/react";

import { styled } from "../../styled-system/jsx";
import bg2 from "../assets/onboarding-bg2.png";
import { FancyImageScreen } from "../components/FancyImageScreen";
import { LoadingButton } from "../components/LoadingButton";
import { PageFrame } from "../components/PageFrame";
import { useModal } from "../lib/hooks/useModal";
import { useToast } from "../lib/hooks/useToast";
import { trpc } from "../lib/trpc";

function InviteHandler({
  linkId,
  onAccept,
}: {
  linkId: string;
  onAccept: (c: { communityId: string }) => Promise<unknown> | void;
}) {
  const toast = useToast();
  const onAcceptAsync = useAsyncCallback(onAccept);
  const [invite] = trpc.community.getCommunityInviteLink.useSuspenseQuery({
    linkId,
  });
  const utils = trpc.useUtils();
  const acceptInviteMutation =
    trpc.community.acceptCommunityInviteLink.useMutation({
      onSuccess: (data) => {
        toast("Invite accepted", { color: "success" });
        void utils.community.invalidate();
        onAcceptAsync.execute(data).catch(console.error);
      },
    });

  return (
    <FancyImageScreen coverImage={bg2}>
      <styled.h2 css={{ textAlign: "center" }}>
        {invite?.inviter?.name} invited you to join {invite?.community?.name}
      </styled.h2>
      <LoadingButton
        expand="full"
        isLoading={acceptInviteMutation.isPending || onAcceptAsync.loading}
        onClick={() => acceptInviteMutation.mutate({ linkId: invite?.id! })}
      >
        Accept Invite
      </LoadingButton>
    </FancyImageScreen>
  );
}

export function InvitePage({
  match,
  onAccept,
}: RouteComponentProps<{ inviteCode: string }> & {
  onAccept: (c: { communityId: string }) => Promise<unknown> | void;
}) {
  return (
    <PageFrame
      immersive
      header={
        <IonButtons slot="start">
          <IonButton routerLink="/">Back</IonButton>
        </IonButtons>
      }
    >
      <InviteHandler linkId={match.params.inviteCode} onAccept={onAccept} />
    </PageFrame>
  );
}

export function useInviteModal({
  onAccept,
}: {
  onAccept?: (c: { communityId: string }) => Promise<unknown> | void;
} = {}) {
  const toast = useToast();
  const modal = useModal<{ linkId: string }>();

  const modalOpen = modal.open;
  const open = useCallback(
    async (linkId?: string) => {
      if (linkId) {
        modalOpen({ linkId });
        return;
      }

      try {
        // this throws an error on web
        if (!(await BarcodeScanner.isSupported()).supported) {
          throw new Error("Barcode scanning not supported");
        }
      } catch (error) {
        toast("Barcode scanning is not supported on this device", {
          color: "danger",
        });
        return;
      }

      try {
        const { camera } = await BarcodeScanner.requestPermissions();
        if (camera !== "granted" && camera !== "limited") {
          toast("Camera permission denied", { color: "danger" });
          return;
        }
        const result = await BarcodeScanner.scan({
          formats: [BarcodeFormat.QrCode],
        });
        if (result.barcodes.length > 0) {
          const url = result.barcodes[0]?.displayValue;
          if (url?.startsWith("https://app.bridgesocial.io/invite/")) {
            const linkId = url.split("/").pop();
            if (linkId) {
              modalOpen({ linkId });
              return;
            }
          }
          toast("Invalid QR Code", { color: "danger" });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [modalOpen, toast]
  );

  return {
    open,
    render: () =>
      modal.render({ title: "Invite", noPadding: true }, (value) => (
        <InviteHandler
          linkId={value.linkId}
          onAccept={async (c) => {
            await onAccept?.(c);
            modal.close();
          }}
        />
      )),
  };
}
