import { useState } from "react";
import emojiData from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";

import { Stack, styled } from "../../../styled-system/jsx";
import { Portal } from "../../components/Portal";

export function useEmojiPicker(onEmojiSelect: (emoji: string) => void) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const renderModal = () =>
    showEmojiPicker && (
      <Portal>
        <Stack
          css={{
            position: "fixed",
            zIndex: 100,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <styled.div
            css={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bg: "black/50",
            }}
            onClick={() => setShowEmojiPicker(false)}
          />
          <EmojiPicker
            data={emojiData}
            onEmojiSelect={(emoji: { native: string }) => {
              onEmojiSelect(emoji.native);
              setShowEmojiPicker(false);
            }}
          />
        </Stack>
      </Portal>
    );

  return { setShowEmojiPicker, renderModal };
}
