import { useEffect } from "react";
import { IonButton } from "@ionic/react";

import { Flex } from "../../styled-system/jsx";

export function ErrorView({ error }: { error: unknown }) {
  useEffect(() => {
    console.error("ErrorView", error);
  }, [error]);

  return (
    <Flex
      css={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "black",
      }}
    >
      <h1>Something went wrong</h1>
      <IonButton fill="clear" onClick={() => window.location.reload()}>
        Reload
      </IonButton>
    </Flex>
  );
}
