import { useState } from "react";
import { TransformComponent,TransformWrapper } from "react-zoom-pan-pinch";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from "@ionic/react";
import { shareSocialOutline } from "ionicons/icons";

import { css, cx } from "../../../styled-system/css";
import { LoadingButton } from "../../components/LoadingButton";
import { useShareImage } from "./useShareImage";

export function useViewImage() {
  const [image, setImage] = useState<{
    url: string;
    filename: string;
  } | null>(null);
  const onClose = () => setImage(null);

  const shareImage = useShareImage();

  const renderModal = () => (
    <IonModal isOpen={!!image} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>Back</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <LoadingButton
              isLoading={shareImage.loading}
              onClick={() => shareImage.execute(image?.url!, image?.filename!)}
            >
              <IonIcon icon={shareSocialOutline} slot="start" />
              Share
            </LoadingButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            bg: "black",
            "& .react-transform-wrapper": {
              width: "100% !important",
              height: "100% !important",
            },
          })}
        >
          <TransformWrapper centerOnInit>
            <TransformComponent>
              <img
                src={image?.url!}
                alt="Selected"
                className={cx(
                  css({ maxWidth: "100%", maxHeight: "100%" }),
                  "ph-no-capture"
                )}
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      </IonContent>
    </IonModal>
  );

  return { view: setImage, renderModal };
}
