import { Stack, styled } from "../../styled-system/jsx";
import logo from "../assets/logo.png";
import { MutedDesc } from "./base";

const StepContainer = styled(Stack, {
  base: {
    minHeight: "100%",
    maxHeight: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    alignItems: "center",
    // justifyContent: "safe center", // not supported on safari
    overflowY: "auto",
    py: "20px",
  },
});

const StepContent = styled("div", {
  base: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: "white/60",
    padding: "20px",
    margin: "20px",
    marginY: "auto",
    borderRadius: "10px",
    backdropFilter: "blur(20px)",
  },
});

export function FancyImageScreen({
  showLogo = false,
  coverImage,
  children,
}: {
  showLogo?: boolean;
  coverImage: string;
  children: React.ReactNode;
}) {
  return (
    <StepContainer style={{ backgroundImage: `url(${coverImage})` }}>
      {showLogo && (
        <styled.img
          src={logo}
          alt="Logo"
          css={{
            position: "absolute",
            top: 100,
            left: 0,
            right: 0,
            width: "100%",
            maxWidth: 200,
            margin: "auto",
            display: "block",
            filter: "drop-shadow(0px 0px 7px white)",
          }}
        />
      )}
      <StepContent>{children}</StepContent>
    </StepContainer>
  );
}

// should be in FancyImageScreen.Header if there's a subtitle
FancyImageScreen.Title = styled("h2", {
  base: {
    textAlign: "center",
    textWrap: "balance",
    mt: 2.5,
    mb: 0,
  },
});

FancyImageScreen.Header = styled(Stack, {
  base: {
    gap: 0,
  },
});

// should be in FancyImageScreen.Header
FancyImageScreen.Subtitle = styled(MutedDesc, {
  base: {
    mt: 0,
    mb: 0,
  },
});
