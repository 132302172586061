import { useEffect } from "react";
import { App } from "@capacitor/app";

import { useUpdatingRef } from "./useUpdatingRef";

export function useBackBtn(callback: () => void) {
  const callbackRef = useUpdatingRef(callback);
  useEffect(() => {
    let canceled = false;
    const handlePromise = App.addListener("backButton", async () => {
      if (canceled) return;
      callbackRef.current();
    });
    return () => {
      canceled = true;
      void handlePromise.then((h) => h.remove());
    };
  }, [callbackRef]);
}
