import { useState } from "react";
import { Popover } from "react-tiny-popover";

import { styled } from "../../styled-system/jsx";
import { RouterOutput } from "../lib/trpc";

export function ReactionPopover({
  reactions,
  children,
}: {
  reactions: RouterOutput["snap"]["getSnapFeedV2"]["snaps"][0]["snapComments"];
  children: React.ReactNode;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["top", "right"]}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <styled.div
          css={{
            bg: "white",
            color: "black",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            padding: "8px",
          }}
        >
          {reactions.map((reaction) => (
            <styled.div key={reaction.id} css={{ padding: "4px 0" }}>
              {reaction.owner.name}: {reaction.text}
            </styled.div>
          ))}
        </styled.div>
      }
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{children}</div>
    </Popover>
  );
}
